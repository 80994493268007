import React, { FC, useState, useEffect } from 'react';

import { CurrentValue, DropdownValuesOut } from './DropdownValues.styles';
import { Dropdown, IconButton, Node, Text, TextProps } from 'combinezone/core';
import { AngleDownSmall } from 'combinezone/icons';
import { useTranslation } from 'react-i18next';

const DropdownValues: FC<{
  initialValue: string;
  onChange: (value: string) => void;
  values: ({ label: string; value: string } | string)[];
  valueTextProps?: TextProps;
}> = ({ initialValue, onChange, values, valueTextProps }) => {
  const { t } = useTranslation('Filters');
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue, setValue]);

  return (
    <DropdownValuesOut>
      <CurrentValue>
        <Text {...valueTextProps}>{t(`Dates.Types.${value}.title`)}</Text>
      </CurrentValue>
      <Dropdown
        position="bottom-right"
        content={
          <div style={{ width: 266 }}>
            {values.map((item) => {
              const listItem =
                typeof item === 'string' ? { label: item, value: item } : item;
              return (
                <Node
                  key={listItem.value}
                  testId="SetSortByLastChecked"
                  accent={listItem.value === value ? 'active' : 'default'}
                  onClick={() => {
                    setValue(listItem.value);
                    onChange(listItem.value);
                  }}
                >
                  {listItem.label}
                </Node>
              );
            })}
          </div>
        }
      >
        <IconButton
          icon={AngleDownSmall}
          tooltip={null}
          testId="SelectSortFiled"
          tabIndex={100}
        />
      </Dropdown>
    </DropdownValuesOut>
  );
};

export default DropdownValues;
