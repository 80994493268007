import React, { Suspense, useCallback, useState } from 'react';

import qs from 'query-string';
import { QueryParamProvider } from 'use-query-params';
import {
  createSearchParams,
  Outlet,
  useMatch,
  useSearchParams,
} from 'react-router-dom';

import { Flex, Spinner, Text } from 'combinezone/core';
import { AsideType } from '#Components/AppAside/consts';
import { Adversary, SocialMedia, UserLeak } from 'combinezone/icons';
import { ServicePage, ServicePageType, Workspaces } from '#Models/common';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import Controls from '../Components/Controls';
import BASE_QUERY_PARAMS_CONFIG from '../params';
import AppAside from '../../../Components/AppAside';
import { LeaksPageRoot } from '../Pages/Leaks/route';
import { MediaPageRoot } from '../Pages/Media/route';
import { LinksPageRoot } from '../Pages/Links/route';
import WorkspaceTitle from '../Components/WorkspaceTitle';
import { ResourcesPageRoot } from '../Pages/Resources/route';
import { SpinnerContentOut, WorkspaceLink } from './Layout.styles';
import {
  ContentOut,
  ControlsOut,
  NavigationOut,
  PageLayoutOut,
  PageTabLink,
  PageTabsOut,
  WorkspaceLayoutIn,
  WorkspaceLayoutOut,
} from './Layout.styles';

export const WorkspaceLayout = () => {
  const isFraud = useMatch({
    path: `/services/${Workspaces.Fraud}`,
    end: false,
  });
  const [sParams] = useSearchParams();

  const [activeTab, setActiveTab] = useState(
    ResourcesPageRoot ? ServicePage.Resources : ServicePage.Links,
  );

  const handleTabChange = (tab: ServicePageType) => {
    setActiveTab(tab);
  };

  const createSearchString = useCallback(
    (params: URLSearchParams): string =>
      createSearchParams(
        ['from', 'to', 'field'].reduce((all, sParamName) => {
          if (params.has(sParamName)) {
            // @ts-ignore
            all.push([sParamName, params.get(sParamName)]);
          }
          return all;
        }, []),
      ).toString(),
    [],
  );

  return (
    <QueryParamProvider
      adapter={ReactRouter6Adapter}
      options={{
        searchStringToObject: qs.parse,
        objectToSearchString: qs.stringify,
        removeDefaultsFromUrl: true,
        params: BASE_QUERY_PARAMS_CONFIG,
      }}
    >
      <WorkspaceLayoutOut>
        <NavigationOut>
          <Flex direction="column">
            <WorkspaceLink
              to={{
                pathname:
                  activeTab === ServicePage.Resources
                    ? ResourcesPageRoot
                    : LinksPageRoot,
                search: createSearchString(sParams),
              }}
            >
              <Adversary />
            </WorkspaceLink>
            <WorkspaceLink
              to={{
                pathname: LeaksPageRoot,
                search: createSearchString(sParams),
              }}
            >
              <UserLeak />
            </WorkspaceLink>
            <WorkspaceLink
              to={{
                pathname: MediaPageRoot,
                search: createSearchString(sParams),
              }}
            >
              <SocialMedia />
            </WorkspaceLink>
          </Flex>
        </NavigationOut>
        <WorkspaceLayoutIn>
          <PageLayoutOut>
            <ControlsOut>
              <WorkspaceTitle />
              {!!isFraud && (
                <PageTabsOut>
                  <PageTabLink
                    to={{
                      pathname: ResourcesPageRoot,
                      search: createSearchString(sParams),
                    }}
                    onClick={() => handleTabChange(ServicePage.Resources)}
                  >
                    Мошеннические ресурсы
                  </PageTabLink>
                  <PageTabLink
                    to={{
                      pathname: LinksPageRoot,
                      search: createSearchString(sParams),
                    }}
                    onClick={() => handleTabChange(ServicePage.Links)}
                  >
                    Другое
                  </PageTabLink>
                </PageTabsOut>
              )}
              <Controls />
            </ControlsOut>
            <ContentOut>
              <Suspense
                fallback={
                  <SpinnerContentOut>
                    <Spinner />
                    <Text>Подождите, пожалуйста, данные обрабатываются...</Text>
                  </SpinnerContentOut>
                }
              >
                <Outlet />
              </Suspense>
            </ContentOut>
          </PageLayoutOut>
        </WorkspaceLayoutIn>
      </WorkspaceLayoutOut>
      <AppAside type={AsideType.Filters} />
    </QueryParamProvider>
  );
};
