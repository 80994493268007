// @ts-nocheck
import React, { FC, useCallback, useEffect, useState } from 'react';
import { graphql } from 'babel-plugin-relay/macro';
import {
  fetchQuery,
  useLazyLoadQuery,
  useRelayEnvironment,
} from 'react-relay/hooks';
import useInterval from '#Hooks/useInterval';
import { DateTime } from 'luxon';
import {
  ButtonTransparent,
  Heading,
  useToast,
} from 'combinezone/core';
import { ToastTitleWithLink } from './ExportTask.styles';
import { ExportTaskStatus } from '#Models/common';
import { useExportTaskQueue } from '#Components/PendingQueues';
import { ExportTaskCheckerQuery } from '#__artifacts/ExportTaskCheckerQuery.graphql';

const query = graphql`
  query ExportTaskCheckerQuery($id: String!) {
    node(id: $id) {
      id
      ... on ExportTask {
        status @required(action: NONE)
        link
      }
    }
  }
`;

const useCheckExportComplete = (
  id: string,
):
  | { status: typeof ExportTaskStatus.Failure }
  | { status: typeof ExportTaskStatus.Success; link: string }
  | undefined => {
  const [fetchKey, updateFetchKey] = useState(
    `${id}__${DateTime.now().toSeconds()}`,
  );

  const environment = useRelayEnvironment();
  const [isRefreshing, setIsRefreshing] = useState(false);

  const data = useLazyLoadQuery<ExportTaskCheckerQuery>(
    query,
    { id },
    { fetchPolicy: 'store-only', fetchKey },
  );

  const refresh = useCallback(() => {
    if (isRefreshing) {
      return;
    }
    setIsRefreshing(true);
    fetchQuery(environment, query, { id }).subscribe({
      complete: () => {
        updateFetchKey(`${id}__${DateTime.now().toSeconds()}`);
        setIsRefreshing(false);
      },
      error: () => {
        setIsRefreshing(false);
      },
    });
  }, [environment, id, isRefreshing]);

  useInterval(refresh, 5000);

  switch (data.node?.status) {
    case ExportTaskStatus.Failure: {
      return { status: ExportTaskStatus.Failure };
    }
    case ExportTaskStatus.Success:
      return {
        status: ExportTaskStatus.Success,
        link: data.node.link as string,
      };
    default:
      return undefined;
  }
};

const ExportTaskChecker: FC<{ taskId: string }> = ({ taskId }) => {
  const { endTask } = useExportTaskQueue();
  const { toastSuccess, toastError } = useToast();
  const result = useCheckExportComplete(taskId);

  useEffect(() => {
    if (result) {
      if (result.status === ExportTaskStatus.Failure) {
        toastError({
          title: 'Что-то пошло не так...',
          message: 'Повторите попытку позже',
          autoClose: true,
          pauseOnHover: false,
        });
      }
      if (result.status === 'Success') {
        toastSuccess({
          title: () => (
            <ToastTitleWithLink>
              <Heading size="md" isInverted>
                Данные
              </Heading>
              <ButtonTransparent
                accent="active"
                testId={result.link}
                onClick={() => window.location.assign(result.link)}
              >
                Скачать
              </ButtonTransparent>
            </ToastTitleWithLink>
          ),
          message: (
            <Heading size="md" isInverted style={{ marginTop: '-6px' }}>
              экспортированы в файл
            </Heading>
          ),
          autoClose: false,
          pauseOnHover: false,
        });
      }
      endTask(result);
    }
  }, [result]);

  return null;
};

export default ExportTaskChecker;
ExportTaskChecker.displayName = 'ExportTaskChecker';
