import { graphql } from 'babel-plugin-relay/macro';

export default graphql`
  mutation DeleteSubscriptionMutation(
    $data: [DeleteNotificationSubscriptionArgsInput!]!
  ) {
    deleteNotificationSubscriptions(data: $data) {
      deleted
      rejections {
        key {
          id
          value
        }
        rejections {
          id
          message
        }
      }
    }
  }
`;
