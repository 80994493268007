/**
 * @generated SignedSource<<2ac7e378b3eabb5353375787861a683c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Role = "Admin" | "Client";
export type AccountPreloaderQuery$variables = {};
export type AccountPreloaderQuery$data = {
  readonly account: {
    readonly email: string | null;
    readonly firstName: string | null;
    readonly lastName: string | null;
    readonly login: string;
    readonly middleName: string | null;
    readonly organization: {
      readonly id: string;
      readonly name: string;
    } | null;
    readonly phone: string | null;
    readonly role: Role;
  };
  readonly organizations: ReadonlyArray<{
    readonly id: string;
    readonly isActive: boolean;
    readonly name: string;
  }> | null;
} | null;
export type AccountPreloaderQuery = {
  response: AccountPreloaderQuery$data;
  variables: AccountPreloaderQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "middleName",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Organization",
  "kind": "LinkedField",
  "name": "organization",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    (v5/*: any*/)
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phone",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "login",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "Organization",
  "kind": "LinkedField",
  "name": "organizations",
  "plural": true,
  "selections": [
    (v5/*: any*/),
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isActive",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountPreloaderQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": null,
          "concreteType": "Account",
          "kind": "LinkedField",
          "name": "account",
          "plural": false,
          "selections": [
            {
              "kind": "RequiredField",
              "field": (v0/*: any*/),
              "action": "NONE",
              "path": "account.role"
            },
            (v1/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/),
            (v6/*: any*/),
            (v7/*: any*/),
            (v8/*: any*/),
            {
              "kind": "RequiredField",
              "field": (v9/*: any*/),
              "action": "NONE",
              "path": "account.login"
            }
          ],
          "storageKey": null
        },
        "action": "NONE",
        "path": "account"
      },
      (v10/*: any*/)
    ],
    "type": "Queries",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AccountPreloaderQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/)
        ],
        "storageKey": null
      },
      (v10/*: any*/)
    ]
  },
  "params": {
    "cacheID": "9a03b936acc18a998d573163cf035f4a",
    "id": null,
    "metadata": {},
    "name": "AccountPreloaderQuery",
    "operationKind": "query",
    "text": "query AccountPreloaderQuery {\n  account {\n    role\n    firstName\n    middleName\n    lastName\n    organization {\n      name\n      id\n    }\n    email\n    phone\n    login\n  }\n  organizations {\n    id\n    name\n    isActive\n  }\n}\n"
  }
};
})();

(node as any).hash = "31362a38ad8e6945e5ad3ca1b5b1f60e";

export default node;
