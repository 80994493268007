/**
 * @generated SignedSource<<af7289bb5c056441fc6d1c4b2839df31>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SubscriptionsLoaderQuery$variables = {
  limit?: number | null;
  nameRegex?: string | null;
  subscriberLogin?: string | null;
};
export type SubscriptionsLoaderQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SubscriptionsConnectionFragment">;
};
export type SubscriptionsLoaderQuery = {
  response: SubscriptionsLoaderQuery$data;
  variables: SubscriptionsLoaderQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "limit"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "nameRegex"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "subscriberLogin"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "include",
  "storageKey": null
},
v4 = [
  (v3/*: any*/)
],
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "states",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SubscriptionsLoaderQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "SubscriptionsConnectionFragment"
      }
    ],
    "type": "Queries",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "SubscriptionsLoaderQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "limit",
            "variableName": "limit"
          },
          {
            "kind": "Variable",
            "name": "nameRegex",
            "variableName": "nameRegex"
          },
          {
            "kind": "Variable",
            "name": "subscriberLogin",
            "variableName": "subscriberLogin"
          }
        ],
        "concreteType": "NotificationSubscription",
        "kind": "LinkedField",
        "name": "notificationSubscriptions",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isActive",
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "format",
                "value": "Human"
              }
            ],
            "kind": "ScalarField",
            "name": "emailPeriod",
            "storageKey": "emailPeriod(format:\"Human\")"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "NotificationSubscriptionQuery",
            "kind": "LinkedField",
            "name": "query",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserFilter",
                "kind": "LinkedField",
                "name": "actor",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "StringIncludeExcludeFilter",
                    "kind": "LinkedField",
                    "name": "organization",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "exclude",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AllEventsSelector",
                "kind": "LinkedField",
                "name": "events",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CreatedFilter",
                    "kind": "LinkedField",
                    "name": "created",
                    "plural": false,
                    "selections": (v4/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "StateChangedFilter",
                    "kind": "LinkedField",
                    "name": "stateChanged",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "from",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "to",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CommentAddedFilter",
                    "kind": "LinkedField",
                    "name": "commentAdded",
                    "plural": false,
                    "selections": (v4/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LeakCollectionAddedFilter",
                    "kind": "LinkedField",
                    "name": "leakCollectionAdded",
                    "plural": false,
                    "selections": (v4/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ClientRequestFilter",
                    "kind": "LinkedField",
                    "name": "clientRequest",
                    "plural": false,
                    "selections": (v4/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "EntitySelector",
                "kind": "LinkedField",
                "name": "entitiesAll",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "services",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "FullByServiceSelectArgs",
                "kind": "LinkedField",
                "name": "entities",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ByServiceSelectMassMediaArgs",
                    "kind": "LinkedField",
                    "name": "massMedia",
                    "plural": false,
                    "selections": (v5/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ByServiceSelectSocialMediaArgs",
                    "kind": "LinkedField",
                    "name": "socialMedia",
                    "plural": false,
                    "selections": (v5/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ByServiceSelectMobileApplicationArgs",
                    "kind": "LinkedField",
                    "name": "mobileApplication",
                    "plural": false,
                    "selections": (v5/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ByServiceSelectSocialAccountArgs",
                    "kind": "LinkedField",
                    "name": "socialAccount",
                    "plural": false,
                    "selections": (v5/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ByServiceSelectFraudulentArgs",
                    "kind": "LinkedField",
                    "name": "fraudulentResource",
                    "plural": false,
                    "selections": (v5/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ByServiceSelectDatabaseArgs",
                    "kind": "LinkedField",
                    "name": "database",
                    "plural": false,
                    "selections": (v5/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ByServiceSelectLimitedAccessArgs",
                    "kind": "LinkedField",
                    "name": "limitedAccess",
                    "plural": false,
                    "selections": (v5/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ByServiceSelectSharingPlatformArgs",
                    "kind": "LinkedField",
                    "name": "sharingPlatform",
                    "plural": false,
                    "selections": (v5/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d1e43685f62f0d021daf2698f6bd322e",
    "id": null,
    "metadata": {},
    "name": "SubscriptionsLoaderQuery",
    "operationKind": "query",
    "text": "query SubscriptionsLoaderQuery(\n  $subscriberLogin: String\n  $limit: Int\n  $nameRegex: String\n) {\n  ...SubscriptionsConnectionFragment\n}\n\nfragment SubscriptionsConnectionFragment on Queries {\n  notificationSubscriptions(subscriberLogin: $subscriberLogin, limit: $limit, nameRegex: $nameRegex) {\n    id\n    name\n    isActive\n    emailPeriod(format: Human)\n    query {\n      actor {\n        organization {\n          include\n          exclude\n        }\n      }\n      events {\n        created {\n          include\n        }\n        stateChanged {\n          from\n          to\n        }\n        commentAdded {\n          include\n        }\n        leakCollectionAdded {\n          include\n        }\n        clientRequest {\n          include\n        }\n      }\n      entitiesAll {\n        services\n      }\n      entities {\n        massMedia {\n          states\n        }\n        socialMedia {\n          states\n        }\n        mobileApplication {\n          states\n        }\n        socialAccount {\n          states\n        }\n        fraudulentResource {\n          states\n        }\n        database {\n          states\n        }\n        limitedAccess {\n          states\n        }\n        sharingPlatform {\n          states\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d524daaca074c290f49ca202e53ea34c";

export default node;
