/**
 * @generated SignedSource<<97717ec6937994534a6f80e23c385bf5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DateResponse = "Long" | "String";
import { FragmentRefs } from "relay-runtime";
export type NewsFeedItemFragment$data = {
  readonly caption: string;
  readonly content: string;
  readonly created: DateResponse;
  readonly id: string;
  readonly imageLink: string | null;
  readonly source: string | null;
  readonly " $fragmentType": "NewsFeedItemFragment";
};
export type NewsFeedItemFragment$key = {
  readonly " $data"?: NewsFeedItemFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NewsFeedItemFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NewsFeedItemFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "content",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "caption",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "source",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "imageLink",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "format",
          "value": "Timestamp"
        }
      ],
      "kind": "ScalarField",
      "name": "created",
      "storageKey": "created(format:\"Timestamp\")"
    }
  ],
  "type": "News",
  "abstractKey": null
};

(node as any).hash = "009bb64f46c8e77b3e836c85b0ecf8c9";

export default node;
