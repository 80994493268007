/**
 * @generated SignedSource<<902304944b666c45eadd917f5ca4a865>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EntitySource = "Bizone" | "BizoneAndClient" | "Client";
import { FragmentRefs } from "relay-runtime";
export type FraudDashboardSuspiciousFragment$data = {
  readonly suspicious: ReadonlyArray<{
    readonly counter: number;
    readonly source: EntitySource | null;
  }> | null;
  readonly " $fragmentType": "FraudDashboardSuspiciousFragment";
};
export type FraudDashboardSuspiciousFragment$key = {
  readonly " $data"?: FraudDashboardSuspiciousFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"FraudDashboardSuspiciousFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "created"
    },
    {
      "kind": "RootArgument",
      "name": "organization"
    },
    {
      "kind": "RootArgument",
      "name": "updated"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "FraudDashboardSuspiciousFragment",
  "selections": [
    {
      "alias": "suspicious",
      "args": [
        {
          "kind": "Variable",
          "name": "created",
          "variableName": "created"
        },
        {
          "kind": "Literal",
          "name": "hidden",
          "value": false
        },
        {
          "kind": "Variable",
          "name": "organization",
          "variableName": "organization"
        },
        {
          "kind": "Literal",
          "name": "states",
          "value": [
            "Correcting",
            "CorrectionDenied",
            "Legitimate",
            "Monitoring",
            "Edited",
            "NoContent",
            "NoViolations",
            "WaitingForCustomer",
            "WaitingForSupport",
            "DelegationRestored"
          ]
        },
        {
          "kind": "Literal",
          "name": "subService",
          "value": [
            "Suspicious"
          ]
        },
        {
          "kind": "Variable",
          "name": "updated",
          "variableName": "updated"
        }
      ],
      "concreteType": "FraudulentCountResponse",
      "kind": "LinkedField",
      "name": "fraudulentResource",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "counter",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "source",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ByServiceCountResponse",
  "abstractKey": null
};

(node as any).hash = "0b058c140f1d80acfa6ad9450d22e935";

export default node;
