/**
 * @generated SignedSource<<ed31db4294c34dcaabf03f53f2f9e6cb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EntitySource = "Bizone" | "BizoneAndClient" | "Client";
export type Priority = "High" | "Low" | "Medium";
export type Service = "Database" | "FraudulentResource" | "LimitedAccess" | "MassMedia" | "MobileApplication" | "SharingPlatform" | "SocialAccount" | "SocialMedia";
export type State = "BlockPending" | "Blocked" | "Checked" | "Correcting" | "CorrectionDenied" | "DelegationRestored" | "Edited" | "InCommunication" | "InProgress" | "Irrelevant" | "Legitimate" | "Monitoring" | "New" | "NoContent" | "NoViolations" | "Settled" | "Unknown" | "Violation" | "WaitingForCustomer" | "WaitingForSupport";
export type SubService = "Fraud" | "None" | "Phishing" | "RelatedUrl" | "Suspicious";
export type Tonality = "Negative" | "Neutral" | "Positive" | "Unset";
export type NotificationSubscriptionArgsInput = {
  emailPeriod?: string | null;
  id?: string | null;
  isActive?: boolean | null;
  name?: string | null;
  query?: NotificationSubscriptionQueryInput | null;
  subscriberLogin?: string | null;
};
export type NotificationSubscriptionQueryInput = {
  actor?: UserFilterInput | null;
  entities?: FullByServiceSelectArgsInput | null;
  entitiesAll?: EntitySelectorInput | null;
  events?: AllEventsSelectorInput | null;
};
export type UserFilterInput = {
  login?: StringIncludeExcludeFilterInput | null;
  organization?: StringIncludeExcludeFilterInput | null;
};
export type StringIncludeExcludeFilterInput = {
  exclude?: ReadonlyArray<string> | null;
  include?: ReadonlyArray<string> | null;
};
export type AllEventsSelectorInput = {
  attachmentAdded?: AttachmentAddedFilterInput | null;
  authFailure?: AuthFailureFilterInput | null;
  authSuccess?: AuthSuccessFilterInput | null;
  clientRequest?: ClientRequestFilterInput | null;
  commentAdded?: CommentAddedFilterInput | null;
  created?: CreatedFilterInput | null;
  hiddenChanged?: HiddenFilterInput | null;
  leakCollectionAdded?: LeakCollectionAddedFilterInput | null;
  logout?: LogoutFilterInput | null;
  reportGenerated?: ReportGeneratedFilterInput | null;
  stateChanged?: StateChangedFilterInput | null;
};
export type CreatedFilterInput = {
  include?: boolean | null;
};
export type StateChangedFilterInput = {
  from?: ReadonlyArray<State> | null;
  to?: ReadonlyArray<State> | null;
};
export type HiddenFilterInput = {
  hidden?: boolean | null;
};
export type ClientRequestFilterInput = {
  include?: boolean | null;
};
export type CommentAddedFilterInput = {
  include?: boolean | null;
};
export type LeakCollectionAddedFilterInput = {
  include?: boolean | null;
};
export type AttachmentAddedFilterInput = {
  include?: boolean | null;
};
export type AuthSuccessFilterInput = {
  include?: boolean | null;
};
export type AuthFailureFilterInput = {
  include?: boolean | null;
};
export type LogoutFilterInput = {
  include?: boolean | null;
};
export type ReportGeneratedFilterInput = {
  include?: boolean | null;
};
export type FullByServiceSelectArgsInput = {
  database?: ByServiceSelectDatabaseArgsInput | null;
  fraudulentResource?: ByServiceSelectFraudulentArgsInput | null;
  limitedAccess?: ByServiceSelectLimitedAccessArgsInput | null;
  massMedia?: ByServiceSelectMassMediaArgsInput | null;
  mobileApplication?: ByServiceSelectMobileApplicationArgsInput | null;
  sharingPlatform?: ByServiceSelectSharingPlatformArgsInput | null;
  socialAccount?: ByServiceSelectSocialAccountArgsInput | null;
  socialMedia?: ByServiceSelectSocialMediaArgsInput | null;
};
export type ByServiceSelectMassMediaArgsInput = {
  created?: LongFromToFilterInput | null;
  hidden?: boolean | null;
  ids?: ReadonlyArray<string> | null;
  link?: string | null;
  organization?: StringIncludeExcludeFilterInput | null;
  priority?: ReadonlyArray<Priority> | null;
  sources?: ReadonlyArray<EntitySource> | null;
  states?: ReadonlyArray<State> | null;
  tags?: AndOrQueryTInput | null;
  tonality?: ReadonlyArray<Tonality> | null;
  tool?: string | null;
  updated?: LongFromToFilterInput | null;
  value?: ReadonlyArray<string> | null;
  valueRegex?: string | null;
};
export type AndOrQueryTInput = {
  and?: ReadonlyArray<string> | null;
  nand?: ReadonlyArray<string> | null;
  nor?: ReadonlyArray<string> | null;
  or?: ReadonlyArray<string> | null;
};
export type LongFromToFilterInput = {
  from?: any | null;
  to?: any | null;
};
export type ByServiceSelectSocialMediaArgsInput = {
  created?: LongFromToFilterInput | null;
  hidden?: boolean | null;
  ids?: ReadonlyArray<string> | null;
  link?: string | null;
  organization?: StringIncludeExcludeFilterInput | null;
  priority?: ReadonlyArray<Priority> | null;
  sourceName?: ReadonlyArray<string> | null;
  sources?: ReadonlyArray<EntitySource> | null;
  states?: ReadonlyArray<State> | null;
  tags?: AndOrQueryTInput | null;
  tonality?: ReadonlyArray<Tonality> | null;
  tool?: string | null;
  updated?: LongFromToFilterInput | null;
  value?: ReadonlyArray<string> | null;
  valueRegex?: string | null;
};
export type ByServiceSelectMobileApplicationArgsInput = {
  created?: LongFromToFilterInput | null;
  detectReason?: string | null;
  fileName?: string | null;
  hidden?: boolean | null;
  ids?: ReadonlyArray<string> | null;
  malware?: boolean | null;
  md5?: string | null;
  organization?: StringIncludeExcludeFilterInput | null;
  priority?: ReadonlyArray<Priority> | null;
  ruleName?: string | null;
  sha1?: string | null;
  sha256?: string | null;
  sourceName?: ReadonlyArray<string> | null;
  sources?: ReadonlyArray<EntitySource> | null;
  states?: ReadonlyArray<State> | null;
  tags?: AndOrQueryTInput | null;
  threatName?: AndOrQueryTInput | null;
  tool?: string | null;
  updated?: LongFromToFilterInput | null;
  value?: ReadonlyArray<string> | null;
  valueRegex?: string | null;
};
export type ByServiceSelectSocialAccountArgsInput = {
  created?: LongFromToFilterInput | null;
  detectReason?: string | null;
  hidden?: boolean | null;
  ids?: ReadonlyArray<string> | null;
  organization?: StringIncludeExcludeFilterInput | null;
  priority?: ReadonlyArray<Priority> | null;
  ruleName?: string | null;
  sourceName?: ReadonlyArray<string> | null;
  sources?: ReadonlyArray<EntitySource> | null;
  states?: ReadonlyArray<State> | null;
  tags?: AndOrQueryTInput | null;
  tool?: string | null;
  updated?: LongFromToFilterInput | null;
  value?: ReadonlyArray<string> | null;
  valueRegex?: string | null;
};
export type ByServiceSelectFraudulentArgsInput = {
  created?: LongFromToFilterInput | null;
  detectReason?: string | null;
  domainZone?: string | null;
  hidden?: boolean | null;
  ids?: ReadonlyArray<string> | null;
  organization?: StringIncludeExcludeFilterInput | null;
  priority?: ReadonlyArray<Priority> | null;
  ruleName?: string | null;
  sources?: ReadonlyArray<EntitySource> | null;
  states?: ReadonlyArray<State> | null;
  subService?: ReadonlyArray<SubService> | null;
  tags?: AndOrQueryTInput | null;
  threatName?: AndOrQueryTInput | null;
  tool?: string | null;
  updated?: LongFromToFilterInput | null;
  value?: ReadonlyArray<string> | null;
  valueRegex?: string | null;
};
export type ByServiceSelectDatabaseArgsInput = {
  cardHolder?: string | null;
  cardNumber?: string | null;
  collectionId?: string | null;
  collectionName?: string | null;
  created?: LongFromToFilterInput | null;
  firstName?: string | null;
  fullName?: string | null;
  hidden?: boolean | null;
  ids?: ReadonlyArray<string> | null;
  ip?: string | null;
  lastName?: string | null;
  leakedDate?: DateFromToFilterInput | null;
  leakedDateDayStart?: LongFromToFilterInput | null;
  organization?: StringIncludeExcludeFilterInput | null;
  password?: string | null;
  passwordHash?: string | null;
  phone?: string | null;
  priority?: ReadonlyArray<Priority> | null;
  sources?: ReadonlyArray<EntitySource> | null;
  states?: ReadonlyArray<State> | null;
  tags?: AndOrQueryTInput | null;
  tool?: string | null;
  updated?: LongFromToFilterInput | null;
  value?: ReadonlyArray<string> | null;
  valueRegex?: string | null;
};
export type DateFromToFilterInput = {
  from?: any | null;
  to?: any | null;
};
export type ByServiceSelectLimitedAccessArgsInput = {
  created?: LongFromToFilterInput | null;
  hidden?: boolean | null;
  ids?: ReadonlyArray<string> | null;
  organization?: StringIncludeExcludeFilterInput | null;
  priority?: ReadonlyArray<Priority> | null;
  sourceName?: ReadonlyArray<string> | null;
  sources?: ReadonlyArray<EntitySource> | null;
  states?: ReadonlyArray<State> | null;
  tags?: AndOrQueryTInput | null;
  telegramId?: string | null;
  telegramNick?: string | null;
  tool?: string | null;
  updated?: LongFromToFilterInput | null;
  value?: ReadonlyArray<string> | null;
  valueRegex?: string | null;
};
export type ByServiceSelectSharingPlatformArgsInput = {
  created?: LongFromToFilterInput | null;
  detectReason?: string | null;
  hidden?: boolean | null;
  ids?: ReadonlyArray<string> | null;
  organization?: StringIncludeExcludeFilterInput | null;
  priority?: ReadonlyArray<Priority> | null;
  sourceName?: ReadonlyArray<string> | null;
  sources?: ReadonlyArray<EntitySource> | null;
  states?: ReadonlyArray<State> | null;
  tags?: AndOrQueryTInput | null;
  tool?: string | null;
  updated?: LongFromToFilterInput | null;
  value?: ReadonlyArray<string> | null;
  valueRegex?: string | null;
};
export type EntitySelectorInput = {
  created?: LongFromToFilterInput | null;
  hidden?: boolean | null;
  ids?: ReadonlyArray<string> | null;
  organization?: StringIncludeExcludeFilterInput | null;
  priority?: ReadonlyArray<Priority> | null;
  services?: ReadonlyArray<Service> | null;
  sources?: ReadonlyArray<EntitySource> | null;
  states?: ReadonlyArray<State> | null;
  tags?: AndOrQueryTInput | null;
  tool?: string | null;
  updated?: LongFromToFilterInput | null;
  value?: ReadonlyArray<string> | null;
  valueRegex?: string | null;
};
export type EditSubscriptionMutation$variables = {
  data: ReadonlyArray<NotificationSubscriptionArgsInput>;
};
export type EditSubscriptionMutation$data = {
  readonly upsertNotificationSubscriptions: {
    readonly data: ReadonlyArray<{
      readonly id: string;
      readonly isActive: boolean;
      readonly name: string;
    }>;
  } | null;
};
export type EditSubscriptionMutation = {
  response: EditSubscriptionMutation$data;
  variables: EditSubscriptionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "data"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "data",
        "variableName": "data"
      }
    ],
    "concreteType": "NotificationSubscriptionAdditionResponse",
    "kind": "LinkedField",
    "name": "upsertNotificationSubscriptions",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "NotificationSubscription",
        "kind": "LinkedField",
        "name": "data",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isActive",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditSubscriptionMutation",
    "selections": (v1/*: any*/),
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditSubscriptionMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "cacf9797277794a52a20451a841b57ee",
    "id": null,
    "metadata": {},
    "name": "EditSubscriptionMutation",
    "operationKind": "mutation",
    "text": "mutation EditSubscriptionMutation(\n  $data: [NotificationSubscriptionArgsInput!]!\n) {\n  upsertNotificationSubscriptions(data: $data) {\n    data {\n      id\n      name\n      isActive\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6719e12e32081b1184070cd36402b09b";

export default node;
