/**
 * @generated SignedSource<<1e808d97767d5644879f19a80c880b8b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ReportConversionFormat = "Docx" | "Pdf";
export type ReportConversionStatus = "Failure" | "Pending" | "Ready" | "Requested" | "SizeLimitExceeded" | "Timeout";
export type ReportStatus = "Failure" | "Pending" | "PendingUpload" | "Ready" | "Timeout" | "Uploaded";
export type ConvertReportCheckerQuery$variables = {
  ids: ReadonlyArray<string>;
};
export type ConvertReportCheckerQuery$data = {
  readonly reports: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly formats: ReadonlyArray<{
          readonly format: ReportConversionFormat;
          readonly result: string | null;
          readonly status: ReportConversionStatus;
        } | null>;
        readonly id: string;
        readonly name: string;
        readonly status: ReportStatus;
      };
    } | null>;
  } | null;
};
export type ConvertReportCheckerQuery = {
  response: ConvertReportCheckerQuery$data;
  variables: ConvertReportCheckerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ids"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "ids",
    "variableName": "ids"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "format",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "result",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ConvertReportCheckerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ReportConnection",
        "kind": "LinkedField",
        "name": "reports",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "alias": null,
              "args": null,
              "concreteType": "ReportEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "kind": "RequiredField",
                  "field": {
                    "alias": null,
                    "args": null,
                    "concreteType": "Report",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "RequiredField",
                        "field": (v2/*: any*/),
                        "action": "LOG",
                        "path": "reports.edges.node.id"
                      },
                      (v3/*: any*/),
                      {
                        "kind": "RequiredField",
                        "field": (v4/*: any*/),
                        "action": "LOG",
                        "path": "reports.edges.node.status"
                      },
                      {
                        "kind": "RequiredField",
                        "field": {
                          "alias": null,
                          "args": null,
                          "concreteType": "ReportFormat",
                          "kind": "LinkedField",
                          "name": "formats",
                          "plural": true,
                          "selections": [
                            (v5/*: any*/),
                            {
                              "kind": "RequiredField",
                              "field": (v4/*: any*/),
                              "action": "LOG",
                              "path": "reports.edges.node.formats.status"
                            },
                            (v6/*: any*/)
                          ],
                          "storageKey": null
                        },
                        "action": "LOG",
                        "path": "reports.edges.node.formats"
                      }
                    ],
                    "storageKey": null
                  },
                  "action": "LOG",
                  "path": "reports.edges.node"
                }
              ],
              "storageKey": null
            },
            "action": "LOG",
            "path": "reports.edges"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Queries",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ConvertReportCheckerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ReportConnection",
        "kind": "LinkedField",
        "name": "reports",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ReportEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Report",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ReportFormat",
                    "kind": "LinkedField",
                    "name": "formats",
                    "plural": true,
                    "selections": [
                      (v5/*: any*/),
                      (v4/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f1ab8d5238112d4b477f28f6a5d664f7",
    "id": null,
    "metadata": {},
    "name": "ConvertReportCheckerQuery",
    "operationKind": "query",
    "text": "query ConvertReportCheckerQuery(\n  $ids: [String!]!\n) {\n  reports(ids: $ids) {\n    edges {\n      node {\n        id\n        name\n        status\n        formats {\n          format\n          status\n          result\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "af487d518b7c4fba4f81b63922638484";

export default node;
