import { graphql } from 'babel-plugin-relay/macro';

export const fragmentEventLog = graphql`
  fragment EventLogConnectionFragment on Queries
  @argumentDefinitions(
    cursor: { type: "String" }
    count: { type: "Int", defaultValue: 14 }
  )
  @refetchable(queryName: "EventLogPaginationQuery") {
    history(
      sort: $sort
      actor: $actor
      events: $events
      first: $count
      after: $cursor
    )
      @connection(
        key: "EventLogConnection__history"
        filters: ["events", "actor"]
      ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          __typename
          actor {
            login
          }
          date
          ... on CommentAddedHistoryEvent {
            commentAdded: comment
            entity {
              value
              id
            }
          }
          ... on EntityHistoryEvent {
            created: entity {
              value
              id
            }
          }
          ... on ClientRequestHistoryEvent {
            clientRequest: entity {
              value
              id
            }
          }
          ... on AttachmentAddedHistoryEvent {
            attachmentAdded: fileName
            entity {
              value
              id
            }
          }
          ... on AuthFailureHistoryEvent {
            authFailure: ip
          }
          ... on LogoutHistoryEvent {
            logout: ip
          }
          ... on AuthSuccessHistoryEvent {
            authSuccess: ip
          }
          ... on ReportGeneratedHistoryEvent {
            reportGenerated: reportName
          }
          ... on StateChangedHistoryEvent {
            stateChanged: state
            previousState
            entity {
              value
              id
            }
          }
          ... on HiddenChangedHistoryEvent {
            hiddenChanged: hidden
            entity {
              value
              id
            }
          }
          ... on LeakCollectionAddedHistoryEvent {
            leakCollectionAdded: collectionName
            entity {
              value
              id
            }
          }
        }
      }
    }
  }
`;
