import { graphql } from 'babel-plugin-relay/macro';

export default graphql`
  mutation ExportEntitiesMutation(
    $baseParams: EntitiesArgs!
    $byServiceParams: ByServiceQueryArgsInput!
  ) {
    exportEntities(
      query: $baseParams
      byServiceQueries: $byServiceParams
      xlsxFormat: { columnOptions: {} }
    ) {
      id
      status
      started(format: Human)
      expires(format: Human)
      link
      error
    }
  }
`;
