/**
 * @generated SignedSource<<de8128219e2ce188c58c0fc21d2f1288>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DateResponse = "Long" | "String";
export type State = "BlockPending" | "Blocked" | "Checked" | "Correcting" | "CorrectionDenied" | "DelegationRestored" | "Edited" | "InCommunication" | "InProgress" | "Irrelevant" | "Legitimate" | "Monitoring" | "New" | "NoContent" | "NoViolations" | "Settled" | "Unknown" | "Violation" | "WaitingForCustomer" | "WaitingForSupport";
import { FragmentRefs } from "relay-runtime";
export type commonsInfoFragment$data = {
  readonly created: DateResponse;
  readonly description: string | null;
  readonly id: string;
  readonly state: State;
  readonly tags: ReadonlyArray<string> | null;
  readonly updated: DateResponse;
  readonly value: string | null;
  readonly " $fragmentType": "commonsInfoFragment";
};
export type commonsInfoFragment$key = {
  readonly " $data"?: commonsInfoFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"commonsInfoFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "format",
    "value": "Timestamp"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "commonsInfoFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "created",
      "storageKey": "created(format:\"Timestamp\")"
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "updated",
      "storageKey": "updated(format:\"Timestamp\")"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tags",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "state",
      "storageKey": null
    }
  ],
  "type": "ServiceEntity",
  "abstractKey": "__isServiceEntity"
};
})();

(node as any).hash = "4e7861b38f941dad470791b077d1ac32";

export default node;
