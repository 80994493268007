import { graphql } from 'babel-plugin-relay/macro';

export const queryEventLog = graphql`
  query EventLogLoaderQuery(
    $actor: UserFilterInput!
    $events: AllEventsSelectorInput
    $sort: DateOrder 
  ) {
    ...EventLogConnectionFragment
  }
`;
