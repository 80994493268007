/**
 * @generated SignedSource<<f510b517c1c2dfe1d49f2e301942aa59>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LongFromToFilterInput = {
  from?: any | null;
  to?: any | null;
};
export type FraudDashboardQuery$variables = {
  created?: LongFromToFilterInput | null;
  organization?: string | null;
  updated?: LongFromToFilterInput | null;
};
export type FraudDashboardQuery$data = {
  readonly count: {
    readonly " $fragmentSpreads": FragmentRefs<"FraudDashboardFraudFragment" | "FraudDashboardMobileApplicationFragment" | "FraudDashboardPhishingFragment" | "FraudDashboardSocialAccountFragment" | "FraudDashboardSuspiciousFragment">;
  } | null;
};
export type FraudDashboardQuery = {
  response: FraudDashboardQuery$data;
  variables: FraudDashboardQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "created"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organization"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "updated"
},
v3 = {
  "kind": "Variable",
  "name": "organization",
  "variableName": "organization"
},
v4 = [
  (v3/*: any*/)
],
v5 = {
  "kind": "Variable",
  "name": "created",
  "variableName": "created"
},
v6 = {
  "kind": "Literal",
  "name": "hidden",
  "value": false
},
v7 = {
  "kind": "Literal",
  "name": "states",
  "value": [
    "InProgress",
    "BlockPending",
    "Blocked"
  ]
},
v8 = {
  "kind": "Variable",
  "name": "updated",
  "variableName": "updated"
},
v9 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "counter",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "source",
    "storageKey": null
  }
],
v10 = [
  (v5/*: any*/),
  (v6/*: any*/),
  (v3/*: any*/),
  {
    "kind": "Literal",
    "name": "states",
    "value": [
      "Correcting",
      "CorrectionDenied",
      "Edited",
      "Legitimate",
      "WaitingForCustomer",
      "WaitingForSupport"
    ]
  },
  (v8/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "FraudDashboardQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "ByServiceCountResponse",
        "kind": "LinkedField",
        "name": "count",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "FraudDashboardFraudFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "FraudDashboardPhishingFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "FraudDashboardSuspiciousFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "FraudDashboardSocialAccountFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "FraudDashboardMobileApplicationFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Queries",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "FraudDashboardQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "ByServiceCountResponse",
        "kind": "LinkedField",
        "name": "count",
        "plural": false,
        "selections": [
          {
            "alias": "fraud",
            "args": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v3/*: any*/),
              (v7/*: any*/),
              {
                "kind": "Literal",
                "name": "subService",
                "value": [
                  "Fraud"
                ]
              },
              (v8/*: any*/)
            ],
            "concreteType": "FraudulentCountResponse",
            "kind": "LinkedField",
            "name": "fraudulentResource",
            "plural": true,
            "selections": (v9/*: any*/),
            "storageKey": null
          },
          {
            "alias": "phishing",
            "args": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v3/*: any*/),
              (v7/*: any*/),
              {
                "kind": "Literal",
                "name": "subService",
                "value": [
                  "Phishing"
                ]
              },
              (v8/*: any*/)
            ],
            "concreteType": "FraudulentCountResponse",
            "kind": "LinkedField",
            "name": "fraudulentResource",
            "plural": true,
            "selections": (v9/*: any*/),
            "storageKey": null
          },
          {
            "alias": "suspicious",
            "args": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v3/*: any*/),
              {
                "kind": "Literal",
                "name": "states",
                "value": [
                  "Correcting",
                  "CorrectionDenied",
                  "Legitimate",
                  "Monitoring",
                  "Edited",
                  "NoContent",
                  "NoViolations",
                  "WaitingForCustomer",
                  "WaitingForSupport",
                  "DelegationRestored"
                ]
              },
              {
                "kind": "Literal",
                "name": "subService",
                "value": [
                  "Suspicious"
                ]
              },
              (v8/*: any*/)
            ],
            "concreteType": "FraudulentCountResponse",
            "kind": "LinkedField",
            "name": "fraudulentResource",
            "plural": true,
            "selections": (v9/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v10/*: any*/),
            "concreteType": "GeneralCountResponse",
            "kind": "LinkedField",
            "name": "socialAccount",
            "plural": true,
            "selections": (v9/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v10/*: any*/),
            "concreteType": "GeneralCountResponse",
            "kind": "LinkedField",
            "name": "mobileApplication",
            "plural": true,
            "selections": (v9/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5383cc85b8d53eb6bb97cad7f2587490",
    "id": null,
    "metadata": {},
    "name": "FraudDashboardQuery",
    "operationKind": "query",
    "text": "query FraudDashboardQuery(\n  $organization: String\n  $created: LongFromToFilterInput\n  $updated: LongFromToFilterInput\n) {\n  count(organization: $organization) {\n    ...FraudDashboardFraudFragment\n    ...FraudDashboardPhishingFragment\n    ...FraudDashboardSuspiciousFragment\n    ...FraudDashboardSocialAccountFragment\n    ...FraudDashboardMobileApplicationFragment\n  }\n}\n\nfragment FraudDashboardFraudFragment on ByServiceCountResponse {\n  fraud: fraudulentResource(organization: $organization, created: $created, updated: $updated, states: [InProgress, BlockPending, Blocked], subService: [Fraud], hidden: false) {\n    counter\n    source\n  }\n}\n\nfragment FraudDashboardMobileApplicationFragment on ByServiceCountResponse {\n  mobileApplication(organization: $organization, created: $created, updated: $updated, states: [Correcting, CorrectionDenied, Edited, Legitimate, WaitingForCustomer, WaitingForSupport], hidden: false) {\n    counter\n    source\n  }\n}\n\nfragment FraudDashboardPhishingFragment on ByServiceCountResponse {\n  phishing: fraudulentResource(organization: $organization, created: $created, updated: $updated, states: [InProgress, BlockPending, Blocked], subService: [Phishing], hidden: false) {\n    counter\n    source\n  }\n}\n\nfragment FraudDashboardSocialAccountFragment on ByServiceCountResponse {\n  socialAccount(organization: $organization, created: $created, updated: $updated, states: [Correcting, CorrectionDenied, Edited, Legitimate, WaitingForCustomer, WaitingForSupport], hidden: false) {\n    counter\n    source\n  }\n}\n\nfragment FraudDashboardSuspiciousFragment on ByServiceCountResponse {\n  suspicious: fraudulentResource(organization: $organization, created: $created, updated: $updated, states: [Correcting, CorrectionDenied, Legitimate, Monitoring, Edited, NoContent, NoViolations, WaitingForCustomer, WaitingForSupport, DelegationRestored], subService: [Suspicious], hidden: false) {\n    counter\n    source\n  }\n}\n"
  }
};
})();

(node as any).hash = "c501c51504a81fad79e342ebd7fdc045";

export default node;
