import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const WorkspaceLayoutOut = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: stretch;
`;

export const WorkspaceLayoutIn = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const PageLayoutOut = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 56px auto 1fr;
  gap: 0;
  grid-template-areas:
    'controls'
    'factoids'
    'content';
  width: 100%;
  height: 100%;
`;
export const PageTabsOut = styled.div`
  display: flex;
  flex-direction: row;
`;

export const PageTabLink = styled(NavLink)`
  display: flex;
  width: fit-content;
  height: 56px;
  padding: 0 16px;
  align-items: center;
  justify-content: center;
  position: relative;
  text-decoration: none;
  color: ${({ theme }) => theme.basis.colors.text.default};
  font-size: 14px;
  font-family: 'Roboto';
  &.active:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 4px;
    background: ${({ theme }) => theme.components.tab.colors.active};
    bottom: 0;
    left: 0;
  }
`;

export const NavigationOut = styled.div`
  display: flex;
  flex-direction: column;
  width: 80px;
  height: 100%;
  z-index: 1;
  background: ${({ theme }) => theme.components.layout.colors.sidebar};
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
`;

export const ControlsOut = styled.div`
  grid-area: controls;
  z-index: 0;
  background: ${({ theme }) => theme.components.layout.colors.toolbar};
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.06);
  padding: 0 16px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  height: 56px;
`;

export const ContentOut = styled.div`
  grid-area: content;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  margin: 0 16px;
  padding: 16px 0;
  box-sizing: border-box;
`;

export const SpinnerContentOut = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: 320px 16px 16px 16px;
  gap: 24px;
  align-items: center;
  width: 100%;
  overflow: hidden;
`;

export const WorkspaceLink = styled(NavLink)`
  display: flex;
  width: 80px;
  height: 68px;
  align-items: center;
  justify-content: center;
  position: relative;
  :hover {
    background-color: ${({ theme }) => theme.components.node.colors.hover};
  }
  &.active:before {
    content: '';
    position: absolute;
    width: 4px;
    height: 100%;
    background: ${({ theme }) => theme.components.tab.colors.active};
    top: 0;
    left: 0;
  }
`;
