/**
 * @generated SignedSource<<cfb80c25b3ae7a5cd708896e345700e7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DateOrder = "NewestFirst" | "OldestFirst";
export type DateResponse = "Long" | "String";
export type EntitySource = "Bizone" | "BizoneAndClient" | "Client";
export type ExportErrorKind = "Internal" | "Interrupted" | "TooManyEntities";
export type ExportTaskStatus = "Failure" | "InProgress" | "Success";
export type Priority = "High" | "Low" | "Medium";
export type ServiceEntityDateField = "Created" | "Updated";
export type State = "BlockPending" | "Blocked" | "Checked" | "Correcting" | "CorrectionDenied" | "DelegationRestored" | "Edited" | "InCommunication" | "InProgress" | "Irrelevant" | "Legitimate" | "Monitoring" | "New" | "NoContent" | "NoViolations" | "Settled" | "Unknown" | "Violation" | "WaitingForCustomer" | "WaitingForSupport";
export type SubService = "Fraud" | "None" | "Phishing" | "RelatedUrl" | "Suspicious";
export type Tonality = "Negative" | "Neutral" | "Positive" | "Unset";
export type EntitiesArgs = {
  created?: LongFromToFilterInput | null;
  hidden?: boolean | null;
  ids?: ReadonlyArray<string> | null;
  limit?: number | null;
  organization?: string | null;
  priority?: ReadonlyArray<Priority> | null;
  sortResults?: SortInput | null;
  sources?: ReadonlyArray<EntitySource> | null;
  states?: ReadonlyArray<State> | null;
  tags?: AndOrQueryTInput | null;
  tool?: string | null;
  updated?: LongFromToFilterInput | null;
  value?: ReadonlyArray<string> | null;
  valueRegex?: string | null;
};
export type AndOrQueryTInput = {
  and?: ReadonlyArray<string> | null;
  nand?: ReadonlyArray<string> | null;
  nor?: ReadonlyArray<string> | null;
  or?: ReadonlyArray<string> | null;
};
export type LongFromToFilterInput = {
  from?: any | null;
  to?: any | null;
};
export type SortInput = {
  field?: ServiceEntityDateField | null;
  order?: DateOrder | null;
};
export type ByServiceQueryArgsInput = {
  database?: ByServiceQueryDatabaseArgsInput | null;
  fraudulentResource?: ByServiceQueryFraudulentArgsInput | null;
  limitedAccess?: ByServiceQueryLimitedAccessArgsInput | null;
  massMedia?: ByServiceQueryMassMediaArgsInput | null;
  mobileApplication?: ByServiceQueryMobileApplicationArgsInput | null;
  sharingPlatform?: ByServiceQuerySharingPlatformArgsInput | null;
  socialAccount?: ByServiceQuerySocialAccountArgsInput | null;
  socialMedia?: ByServiceQuerySocialMediaArgsInput | null;
};
export type ByServiceQueryMassMediaArgsInput = {
  created?: LongFromToFilterInput | null;
  hidden?: boolean | null;
  ids?: ReadonlyArray<string> | null;
  limit?: number | null;
  link?: string | null;
  organization?: string | null;
  priority?: ReadonlyArray<Priority> | null;
  sortResults?: SortInput | null;
  sources?: ReadonlyArray<EntitySource> | null;
  states?: ReadonlyArray<State> | null;
  tags?: AndOrQueryTInput | null;
  tonality?: ReadonlyArray<Tonality> | null;
  tool?: string | null;
  updated?: LongFromToFilterInput | null;
  value?: ReadonlyArray<string> | null;
  valueRegex?: string | null;
};
export type ByServiceQuerySocialMediaArgsInput = {
  created?: LongFromToFilterInput | null;
  hidden?: boolean | null;
  ids?: ReadonlyArray<string> | null;
  limit?: number | null;
  link?: string | null;
  organization?: string | null;
  priority?: ReadonlyArray<Priority> | null;
  sortResults?: SortInput | null;
  sourceName?: ReadonlyArray<string> | null;
  sources?: ReadonlyArray<EntitySource> | null;
  states?: ReadonlyArray<State> | null;
  tags?: AndOrQueryTInput | null;
  tonality?: ReadonlyArray<Tonality> | null;
  tool?: string | null;
  updated?: LongFromToFilterInput | null;
  value?: ReadonlyArray<string> | null;
  valueRegex?: string | null;
};
export type ByServiceQueryMobileApplicationArgsInput = {
  created?: LongFromToFilterInput | null;
  detectReason?: string | null;
  fileName?: string | null;
  hidden?: boolean | null;
  ids?: ReadonlyArray<string> | null;
  limit?: number | null;
  malware?: boolean | null;
  md5?: string | null;
  organization?: string | null;
  priority?: ReadonlyArray<Priority> | null;
  ruleName?: string | null;
  sha1?: string | null;
  sha256?: string | null;
  sortResults?: SortInput | null;
  sourceName?: ReadonlyArray<string> | null;
  sources?: ReadonlyArray<EntitySource> | null;
  states?: ReadonlyArray<State> | null;
  tags?: AndOrQueryTInput | null;
  threatName?: AndOrQueryTInput | null;
  tool?: string | null;
  updated?: LongFromToFilterInput | null;
  value?: ReadonlyArray<string> | null;
  valueRegex?: string | null;
};
export type ByServiceQuerySocialAccountArgsInput = {
  created?: LongFromToFilterInput | null;
  detectReason?: string | null;
  hidden?: boolean | null;
  ids?: ReadonlyArray<string> | null;
  limit?: number | null;
  organization?: string | null;
  priority?: ReadonlyArray<Priority> | null;
  ruleName?: string | null;
  sortResults?: SortInput | null;
  sourceName?: ReadonlyArray<string> | null;
  sources?: ReadonlyArray<EntitySource> | null;
  states?: ReadonlyArray<State> | null;
  tags?: AndOrQueryTInput | null;
  tool?: string | null;
  updated?: LongFromToFilterInput | null;
  value?: ReadonlyArray<string> | null;
  valueRegex?: string | null;
};
export type ByServiceQueryFraudulentArgsInput = {
  created?: LongFromToFilterInput | null;
  detectReason?: string | null;
  domainZone?: string | null;
  hidden?: boolean | null;
  ids?: ReadonlyArray<string> | null;
  limit?: number | null;
  organization?: string | null;
  priority?: ReadonlyArray<Priority> | null;
  ruleName?: string | null;
  sortResults?: SortInput | null;
  sources?: ReadonlyArray<EntitySource> | null;
  states?: ReadonlyArray<State> | null;
  subService?: ReadonlyArray<SubService> | null;
  tags?: AndOrQueryTInput | null;
  threatName?: AndOrQueryTInput | null;
  tool?: string | null;
  updated?: LongFromToFilterInput | null;
  value?: ReadonlyArray<string> | null;
  valueRegex?: string | null;
};
export type ByServiceQueryDatabaseArgsInput = {
  cardHolder?: string | null;
  cardNumber?: string | null;
  collectionId?: string | null;
  collectionName?: string | null;
  created?: LongFromToFilterInput | null;
  firstName?: string | null;
  fullName?: string | null;
  hidden?: boolean | null;
  ids?: ReadonlyArray<string> | null;
  ip?: string | null;
  lastName?: string | null;
  leakedDate?: DateFromToFilterInput | null;
  leakedDateDayStart?: LongFromToFilterInput | null;
  limit?: number | null;
  organization?: string | null;
  password?: string | null;
  passwordHash?: string | null;
  phone?: string | null;
  priority?: ReadonlyArray<Priority> | null;
  sortResults?: SortInput | null;
  sources?: ReadonlyArray<EntitySource> | null;
  states?: ReadonlyArray<State> | null;
  tags?: AndOrQueryTInput | null;
  tool?: string | null;
  updated?: LongFromToFilterInput | null;
  value?: ReadonlyArray<string> | null;
  valueRegex?: string | null;
};
export type DateFromToFilterInput = {
  from?: any | null;
  to?: any | null;
};
export type ByServiceQueryLimitedAccessArgsInput = {
  created?: LongFromToFilterInput | null;
  hidden?: boolean | null;
  ids?: ReadonlyArray<string> | null;
  limit?: number | null;
  organization?: string | null;
  priority?: ReadonlyArray<Priority> | null;
  sortResults?: SortInput | null;
  sourceName?: ReadonlyArray<string> | null;
  sources?: ReadonlyArray<EntitySource> | null;
  states?: ReadonlyArray<State> | null;
  tags?: AndOrQueryTInput | null;
  telegramId?: string | null;
  telegramNick?: string | null;
  tool?: string | null;
  updated?: LongFromToFilterInput | null;
  value?: ReadonlyArray<string> | null;
  valueRegex?: string | null;
};
export type ByServiceQuerySharingPlatformArgsInput = {
  created?: LongFromToFilterInput | null;
  detectReason?: string | null;
  hidden?: boolean | null;
  ids?: ReadonlyArray<string> | null;
  limit?: number | null;
  organization?: string | null;
  priority?: ReadonlyArray<Priority> | null;
  sortResults?: SortInput | null;
  sourceName?: ReadonlyArray<string> | null;
  sources?: ReadonlyArray<EntitySource> | null;
  states?: ReadonlyArray<State> | null;
  tags?: AndOrQueryTInput | null;
  tool?: string | null;
  updated?: LongFromToFilterInput | null;
  value?: ReadonlyArray<string> | null;
  valueRegex?: string | null;
};
export type ExportEntitiesMutation$variables = {
  baseParams: EntitiesArgs;
  byServiceParams: ByServiceQueryArgsInput;
};
export type ExportEntitiesMutation$data = {
  readonly exportEntities: {
    readonly error: ExportErrorKind | null;
    readonly expires: DateResponse;
    readonly id: string;
    readonly link: string | null;
    readonly started: DateResponse;
    readonly status: ExportTaskStatus;
  } | null;
};
export type ExportEntitiesMutation = {
  response: ExportEntitiesMutation$data;
  variables: ExportEntitiesMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "baseParams"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "byServiceParams"
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "format",
    "value": "Human"
  }
],
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "byServiceQueries",
        "variableName": "byServiceParams"
      },
      {
        "kind": "Variable",
        "name": "query",
        "variableName": "baseParams"
      },
      {
        "kind": "Literal",
        "name": "xlsxFormat",
        "value": {
          "columnOptions": {}
        }
      }
    ],
    "concreteType": "ExportTask",
    "kind": "LinkedField",
    "name": "exportEntities",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "kind": "ScalarField",
        "name": "started",
        "storageKey": "started(format:\"Human\")"
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "kind": "ScalarField",
        "name": "expires",
        "storageKey": "expires(format:\"Human\")"
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "link",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ExportEntitiesMutation",
    "selections": (v2/*: any*/),
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ExportEntitiesMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "d1edda789180eb9dd9bb3fc5de68f8a9",
    "id": null,
    "metadata": {},
    "name": "ExportEntitiesMutation",
    "operationKind": "mutation",
    "text": "mutation ExportEntitiesMutation(\n  $baseParams: EntitiesArgs!\n  $byServiceParams: ByServiceQueryArgsInput!\n) {\n  exportEntities(query: $baseParams, byServiceQueries: $byServiceParams, xlsxFormat: {columnOptions: {}}) {\n    id\n    status\n    started(format: Human)\n    expires(format: Human)\n    link\n    error\n  }\n}\n"
  }
};
})();

(node as any).hash = "270e7a56490181dbeafa38f7efb69f9e";

export default node;
