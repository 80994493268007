/**
 * @generated SignedSource<<92f10cb16f659638af9a4c99717e1e58>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteNotificationSubscriptionArgsInput = {
  id: string;
};
export type DeleteSubscriptionMutation$variables = {
  data: ReadonlyArray<DeleteNotificationSubscriptionArgsInput>;
};
export type DeleteSubscriptionMutation$data = {
  readonly deleteNotificationSubscriptions: {
    readonly deleted: ReadonlyArray<string>;
    readonly rejections: ReadonlyArray<{
      readonly key: {
        readonly id: string | null;
        readonly value: string | null;
      };
      readonly rejections: ReadonlyArray<{
        readonly id: string;
        readonly message: string;
      }>;
    }>;
  } | null;
};
export type DeleteSubscriptionMutation = {
  response: DeleteSubscriptionMutation$data;
  variables: DeleteSubscriptionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "data"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "data",
        "variableName": "data"
      }
    ],
    "concreteType": "StringDeletionResponse",
    "kind": "LinkedField",
    "name": "deleteNotificationSubscriptions",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deleted",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Rejection",
        "kind": "LinkedField",
        "name": "rejections",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RejectionKey",
            "kind": "LinkedField",
            "name": "key",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "value",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "RejectionMessage",
            "kind": "LinkedField",
            "name": "rejections",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "message",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteSubscriptionMutation",
    "selections": (v2/*: any*/),
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteSubscriptionMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "b624f2f4905fc83a5e34f54d6696e0af",
    "id": null,
    "metadata": {},
    "name": "DeleteSubscriptionMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteSubscriptionMutation(\n  $data: [DeleteNotificationSubscriptionArgsInput!]!\n) {\n  deleteNotificationSubscriptions(data: $data) {\n    deleted\n    rejections {\n      key {\n        id\n        value\n      }\n      rejections {\n        id\n        message\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "86705bff103c9fcf1bdc00f51a2a4c15";

export default node;
