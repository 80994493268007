/**
 * @generated SignedSource<<ada0c6f5a9659e59a1f83642967b6e87>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Tonality = "Negative" | "Neutral" | "Positive" | "Unset";
import { FragmentRefs } from "relay-runtime";
export type MediaDashboardMassMediaFragment$data = {
  readonly massMedia: ReadonlyArray<{
    readonly counter: number;
    readonly tonality: Tonality | null;
  }> | null;
  readonly " $fragmentType": "MediaDashboardMassMediaFragment";
};
export type MediaDashboardMassMediaFragment$key = {
  readonly " $data"?: MediaDashboardMassMediaFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"MediaDashboardMassMediaFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "created"
    },
    {
      "kind": "RootArgument",
      "name": "organization"
    },
    {
      "kind": "RootArgument",
      "name": "updated"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "MediaDashboardMassMediaFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "created",
          "variableName": "created"
        },
        {
          "kind": "Literal",
          "name": "hidden",
          "value": false
        },
        {
          "kind": "Variable",
          "name": "organization",
          "variableName": "organization"
        },
        {
          "kind": "Variable",
          "name": "updated",
          "variableName": "updated"
        }
      ],
      "concreteType": "MediaCountResponse",
      "kind": "LinkedField",
      "name": "massMedia",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "counter",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tonality",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ByServiceCountResponse",
  "abstractKey": null
};

(node as any).hash = "f7f904455633cecc1db4f4df1ce249fd";

export default node;
