import { useMatch } from 'react-router-dom';

import { Workspaces } from '../Models/common';

export const useServicesMatch = () => {
  const isFraud = useMatch({
    path: `/services/${Workspaces.Fraud}`,
    end: false,
  });
  const isLeaks = useMatch({
    path: `/services/${Workspaces.Leaks}`,
    end: false,
  });
  const isMedia = useMatch({
    path: `/services/${Workspaces.Media}`,
    end: false,
  });
  const isResources = useMatch({
    path: `/services/${Workspaces.Fraud}/resources`,
    end: false,
  });
  const isLinks = useMatch({
    path: `/services/${Workspaces.Fraud}/links`,
    end: false,
  });

  return { isFraud, isLeaks, isMedia, isResources, isLinks };
};
