import { FC, useMemo } from 'react';

import { DateTime } from 'luxon';
import { useQueryParams } from 'use-query-params';

import CustomRangeDatepicker from '#Components/CustomRangeDatepicker';
import { RangeDatepickerResult } from 'combinezone/core/Datepicker/types';

import { ControlsOut } from './DashboardControls.styles';

const setDateWithTime = (date: Date | undefined) => {
    if (!date) {
        return;
    }

    let luxonDate = DateTime.fromJSDate(date);

    if (luxonDate.toISODate() === DateTime.local().toISODate()) {
        luxonDate = luxonDate.set({
            hour: DateTime.local().hour,
            minute: DateTime.local().minute,
            second: DateTime.local().second,
        });
    } else {
        luxonDate = luxonDate.endOf('day');
    }
    return luxonDate;
};

const DashboardControls: FC<{
    setIsCalendarDatesUpdated: (value: boolean) => void;
}> = ({ setIsCalendarDatesUpdated }) => {

    const [{ from, to }, setParams] = useQueryParams();

    const rangeValue = useMemo<RangeDatepickerResult>(
        () => [
            DateTime.fromSeconds(from).toJSDate(),
            to ? DateTime.fromSeconds(to).toJSDate() : undefined,
        ],
        [from, to],
    );

    const currentDate = DateTime.local();
    const startOfDay = DateTime.now().startOf('day').toJSDate();
    const endOfDay = DateTime.now().toJSDate(); // когда есть disabledDays вычитается 1 секунда addSecond = -1
    const oneWeekAgo = currentDate.minus({ days: 6 }).startOf('day').toJSDate();
    const twoWeeksAgo = currentDate.minus({ days: 13 }).startOf('day').toJSDate();
    const oneMonthAgo = currentDate
        .minus({ months: 1 })
        .plus({ days: 1 })
        .startOf('day')
        .toJSDate();
    const threeMonthAgo = currentDate
        .minus({ months: 3 })
        .plus({ days: 1 })
        .startOf('day')
        .toJSDate();
    const yearAgo = currentDate.minus({ year: 1 }).startOf('day').toJSDate();

    return (
        <ControlsOut>
            <CustomRangeDatepicker
                size="medium"
                testId="DataPickerDashboard"
                isTimepickerEnabled
                onChange={(range: any) => {
                    if (!!range) {
                        const [from, to] = range;
                        setIsCalendarDatesUpdated(true);
                        setParams({
                            from: Math.floor(DateTime.fromJSDate(from as Date).toSeconds()),
                            to: Math.floor(Number(setDateWithTime(to)?.toSeconds())),
                        });
                    }
                    return { from: null, to: null };
                }}
                dateFormat="dd.MM.yyyy HH:mm"
                presetsTitle="Период"
                autoSubmit={false}
                disabledDays={{
                    after: currentDate.endOf('day').toJSDate(),
                }}
                presets={[
                    {
                        title: 'Сегодня',
                        dateValue: [startOfDay, endOfDay],
                    },
                    {
                        title: 'За неделю',
                        dateValue: [oneWeekAgo, endOfDay],
                    },
                    {
                        title: 'За 2 недели',
                        dateValue: [twoWeeksAgo, endOfDay],
                    },
                    {
                        title: 'За месяц',
                        dateValue: [oneMonthAgo, endOfDay],
                    },
                    {
                        title: 'За 3 месяца',
                        dateValue: [threeMonthAgo, endOfDay],
                    },
                    {
                        title: 'За год',
                        dateValue: [yearAgo, endOfDay],
                    },
                ]}
                value={rangeValue}
                dropdownProps={{
                    position: 'bottom-left',
                    returnFocusOnClose: false,
                    closeOnBlur: false,
                }}
            />
        </ControlsOut>
    );
};

export default DashboardControls;
