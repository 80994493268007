import { FC } from 'react';

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { useToast } from 'combinezone/core';

import { Field, Form } from '#Form';
import PhoneNumberInput from '../../Components/PhoneNumberInput';
import FormSubmit from '#Form/Form_Actions';
import { validateEmail, validatePhone } from '#Modals/common';
import ServicesButtons from '#Components/GenericButtons/ServicesButtons';
import { RegistrationOut, ToastIn } from './Registration.styles';
import {
  FormControlsRow,
  StyledInput,
  StyledTextarea,
} from '#Modules/Auth/Layout/Layout.styles';
import RegistrationSuccessRoute from '#Modules/Auth/Pages/RegistrationSuccess/route';
import { useNavigate } from 'react-router-dom';

const errorMessage = (
  <ToastIn>
    Повторите попытку позже. Если проблема повторится{' '}
    <a href="mailto:bp@bi.zone" className="emailLink">
      свяжитесь с нами
    </a>
  </ToastIn>
) as unknown as string;

type RegistrationPageProps = {
  email: string;
  phone: string;
  fullName: string;
  organization: string;
  services: string[];
  comment?: string;
};

const FORM_ID = 'RegistrationPage';

const RegistrationPage: FC = () => {
  const { toastError } = useToast();
  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const action = 'bp_registration_request';

  const handleRegister = async ({
    email,
    phone,
    fullName,
    organization,
    services,
    comment,
  }: RegistrationPageProps): Promise<void> => {
    const recaptchaToken = await executeRecaptcha?.(action);

    const listServices = services?.length > 0 ? `[${services}]` : null;

    const request = `
    mutation {
      requestRegistration(
        email: "${email}"
        phone: "${phone}"
        fullName: "${fullName}"
        organization: "${organization}"
        services:${listServices}
        comment: "${comment}"
      )
    }
  `;

    const requestBody = {
      query: request,
      variables: {},
    };

    try {
      const response = await fetch('/api/v2/bp/graphql', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Brand-Protection-Captcha': `bp_registration_request ${recaptchaToken}`,
        },
        body: JSON.stringify(requestBody),
      });
      const data = await response.json();

      const {
        data: { requestRegistration },
      } = data;

      if (requestRegistration) {
        navigate(`/auth/${RegistrationSuccessRoute.path}`, {
          state: {
            registrationSuccess: true,
          },
          replace: true,
        });
      }
    } catch (error) {
      toastError({
        title: 'Что-то пошло не так …',
        message: errorMessage,
      });
    }
  };

  return (
    <RegistrationOut>
      <Form
        onSubmit={handleRegister}
        formId={FORM_ID}
        defaultValues={{ phone: '+7' }}
      >
        <Field
          name="email"
          label="Корпоративная почта"
          isRequired="Поле обязательно для заполнения"
          element={<StyledInput testId="email" />}
          validate={validateEmail}
        />
        <Field
          name="phone"
          label="Телефон"
          isRequired="Поле обязательно для заполнения"
          element={<PhoneNumberInput testId="phone" />}
          validate={validatePhone}
        />
        <Field
          name="fullName"
          label="ФИО"
          isRequired="Поле обязательно для заполнения"
          element={<StyledInput testId="fullName" />}
        />
        <Field
          name="organization"
          label="Организация"
          isRequired="Поле обязательно для заполнения"
          element={<StyledInput testId="organization" />}
        />
        <Field
          name="services"
          label="Направления"
          element={<ServicesButtons />}
        />
        <Field
          name="comment"
          label="Комментарий"
          element={
            <StyledTextarea
              testId="comment"
              placeholder="Дополнительные пожелания"
            />
          }
        />
        <FormControlsRow>
          <FormSubmit formId={FORM_ID}>Запросить</FormSubmit>
        </FormControlsRow>
      </Form>
    </RegistrationOut>
  );
};

export default RegistrationPage;
