import { graphql } from 'babel-plugin-relay/macro';

export default graphql`
  mutation UpsertSubscriptionMutation(
    $data: [NotificationSubscriptionArgsInput!]!
  ) {
    upsertNotificationSubscriptions(data: $data) {
      data {
        id
        name
        isActive
        emailPeriod
        query {
          actor {
            organization {
              include
              exclude
            }
          }
          events {
            created {
              include
            }
            stateChanged {
              from
              to
            }
            commentAdded {
              include
            }
            leakCollectionAdded {
              include
            }
            clientRequest {
              include
            }
          }
          entitiesAll {
            services
          }
        }
      }
      rejections {
        key {
          id
          value
        }
        rejections {
          id
          message
        }
      }
    }
  }
`;
