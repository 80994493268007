import Organizations from '../Organizations';
import Filters from '../Filters/Filters';

export const AsideType = {
  Organizations: 'Organizations',
  Filters: 'Filters',
} as const;

export const ASIDES = {
  [AsideType.Organizations]: {
    title: 'Организации',
    Component: Organizations,
  },
  [AsideType.Filters]: {
    title: 'Фильтры',
    Component: Filters,
  },
} as const;
