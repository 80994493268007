import { action, computed, observable, makeObservable } from 'mobx';

class SelectionStore {
  selectedMap: Record<string, any> = new Map();
  allCount: number = 0;
  isAllChecked: boolean = false;

  get selected(): any[] {
    return Array.from(this.selectedMap.values());
  }
  get selectedIds(): string[] {
    return Array.from(this.selectedMap.keys());
  }

  setAllChecked = (): void => {
    this.isAllChecked = true;
  };

  resetAllChecked = (): void => {
    this.isAllChecked = false;
  };

  toggleSelected = (currentEntity: any): void => {
    if (currentEntity && currentEntity.id) {
      if (this.selectedMap.has(currentEntity.id))
        this.selectedMap.delete(currentEntity.id);
      else this.selectedMap.set(currentEntity.id, currentEntity);
      this.isAllChecked = false;
    }
  };

  addToSelection = (currentEntity: any): void => {
    if (currentEntity && currentEntity.id) {
      if (!this.selectedMap.has(currentEntity.id))
        this.selectedMap.set(currentEntity.id, currentEntity);
    }
  };

  setAllCount = (count: number): void => {
    this.allCount = count;
  };

  resetSelection = () => {
    this.selectedMap.clear();
  };

  constructor() {
    makeObservable(this, {
      selectedMap: observable,
      isAllChecked: observable,
      allCount: observable,
      selectedIds: computed,
      selected: computed,
      toggleSelected: action,
      setAllChecked: action,
      resetAllChecked: action,
      addToSelection: action,
      resetSelection: action,
      setAllCount: action,
    });
  }
}

export default SelectionStore;
