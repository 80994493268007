/**
 * @generated SignedSource<<600313bc498b12e7cec27a74093ccbab>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LeaksDashboardLimitedAccessFragment$data = {
  readonly limitedAccess: ReadonlyArray<{
    readonly counter: number;
  }> | null;
  readonly " $fragmentType": "LeaksDashboardLimitedAccessFragment";
};
export type LeaksDashboardLimitedAccessFragment$key = {
  readonly " $data"?: LeaksDashboardLimitedAccessFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"LeaksDashboardLimitedAccessFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "created"
    },
    {
      "kind": "RootArgument",
      "name": "organization"
    },
    {
      "kind": "RootArgument",
      "name": "updated"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "LeaksDashboardLimitedAccessFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "created",
          "variableName": "created"
        },
        {
          "kind": "Literal",
          "name": "hidden",
          "value": false
        },
        {
          "kind": "Variable",
          "name": "organization",
          "variableName": "organization"
        },
        {
          "kind": "Variable",
          "name": "updated",
          "variableName": "updated"
        }
      ],
      "concreteType": "GeneralCountResponse",
      "kind": "LinkedField",
      "name": "limitedAccess",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "counter",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ByServiceCountResponse",
  "abstractKey": null
};

(node as any).hash = "e2d74d2d3e41d7376722d6790b9c8f07";

export default node;
