import React, {
  createRef,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import en from 'combinezone/core/Select/locales/en';
import ru from 'combinezone/core/Select/locales/ru';
import { useTranslate } from 'combinezone/utils/hooks/useTranslate';
import { Options } from 'combinezone/core/Select/components/Options';
import { addOrRemove } from 'combinezone/core/Select/utils/addOrRemove';
import { Dropdown, DropdownProps } from 'combinezone/core/Dropdown/Dropdown';
import { SelectInput } from 'combinezone/core/Select/components/SelectInput';
import { Context, SelectContextType } from 'combinezone/core/Select/context';
import { OptionsHeader } from 'combinezone/core/Select/components/OptionsHeader';
import { isOptionMatchSearch } from 'combinezone/core/Select/utils/isOptionMatchSearch';
import {
  getItemValue,
  isOption,
  NormalizedSelectOption,
  SelectItem,
  SelectOption,
  SHOW_OPTIONS_COUNT,
} from 'combinezone/core/Select/types';

const locales = { en, ru };

export type SingleSelectProps = {
  multiple?: false;
  value?: string;
  onChange?: (res?: SelectOption['value']) => void;
};

export type MultiSelectProps = {
  multiple: true;
  value?: string[];
  onChange?: (res: SelectOption['value'][]) => void;
};

export type SelectProps = (SingleSelectProps | MultiSelectProps) & {
  disabled?: boolean;
  placeholder?: string;
  dropdownProps?: Partial<DropdownProps>;
  error?: boolean;
  inputClassName?: string;
  testId: string;
  warning?: boolean;
  className?: string;
  options: SelectItem[];
  onSearchStringChange?: (search: string) => void;
  virtualOverscan?: number;
  searchPlaceholder?: string;
};

// TODO: focus lock
const CustomSelect = forwardRef<HTMLButtonElement, SelectProps>(
  (
    {
      className,
      disabled: isDisabled,
      dropdownProps,
      error: isInvalid,
      inputClassName,
      onSearchStringChange,
      options,
      placeholder,
      searchPlaceholder,
      testId,
      virtualOverscan,
      ...rest
    },
    ref,
  ) => {
    const isDropdownOpenedRef = useRef(false);
    const closeDropdownRef = useRef(() => {
      console.error('[Select] closeDropdownRef is not implemented');
    });
    const optionsHeaderContainerRef = createRef<HTMLDivElement>();
    const searchInputRef = useRef<HTMLInputElement>(null);
    const toggleOptionsRef = useRef<HTMLSpanElement>(null);
    const firstOptionRef = useRef<HTMLDivElement>(null);
    const [searchString, setSearchString] = useState('');
    const [returnFocusOnClose, setReturnFocusOnClose] = useState(false);

    const handlerChangeSearchString = (search: string): void => {
      setSearchString(search);
      if (onSearchStringChange) {
        onSearchStringChange(search);
      }
    };

    const t = useTranslate(locales);

    const multipleValue = useMemo(
      () =>
        rest.value === undefined || Array.isArray(rest.value)
          ? rest.value
          : [rest.value],
      [rest.value],
    );

    const normalizedOptions = useMemo(
      () =>
        options?.map((item): NormalizedSelectOption => {
          const option = isOption(item)
            ? {
              ...item,
              testId: item.testId ?? `${testId}-${item.value}`,
            }
            : {
              content: item,
              value: item,
              testId: `${testId}-${item}`,
            };

          if (option.value === undefined) {
            throw new Error(
              `[Select] Undefined value of an option:\n${JSON.stringify(
                item,
                null,
                '\t',
              )}`,
            );
          } else {
            return option;
          }
        }),
      [options, testId],
    );

    const withSearch =
      normalizedOptions.length > SHOW_OPTIONS_COUNT ||
      searchString.length > 0 ||
      !!onSearchStringChange;
    const [filteredOptions, setFilteredOptions] = useState(normalizedOptions);

    const [selectedOptions, setSelectedOptions] = useState(
      normalizedOptions?.filter(({ value }) =>
        multipleValue?.includes(value),
      ) || [],
    );

    useEffect(() => {
      if (!multipleValue) {
        setSelectedOptions([]);
        return;
      }

      setSelectedOptions((prevSelectedOptions) => {
        const savedSelectedOptions = prevSelectedOptions.filter(
          ({ value }) =>
            !normalizedOptions.find((option) => value === option.value),
        );
        return [...savedSelectedOptions, ...normalizedOptions].filter(
          ({ value }) => multipleValue?.includes(value),
        );
      });
    }, [normalizedOptions, multipleValue]);

    useEffect(() => {
      setFilteredOptions(
        !searchString || onSearchStringChange
          ? normalizedOptions
          : normalizedOptions?.filter((item) =>
            isOptionMatchSearch(item, searchString),
          ),
      );
    }, [
      normalizedOptions,
      searchString,
      setFilteredOptions,
      onSearchStringChange,
    ]);

    const toggleOption: SelectContextType['toggleOption'] = useCallback(
      (optionValue: any) => {
        if (rest.multiple) {
          const newValues = addOrRemove(rest.value ?? [], optionValue);
          rest.onChange?.(newValues);

          return;
        }

        const newValue = rest.value === optionValue ? rest.value : optionValue;
        rest.onChange?.(newValue);
        closeDropdownRef.current();
      },
      [rest],
    );

    const clearAll: SelectContextType['clearAll'] = useCallback(() => {
      if (rest.multiple) {
        rest.onChange?.([]);
      }
    }, [rest]);

    const selectAll: SelectContextType['selectAll'] = useCallback(() => {
      const allValues = filteredOptions.map(getItemValue);

      if (rest.multiple) {
        rest.onChange?.(allValues);
      }
    }, [rest, filteredOptions]);

    return (
      <Context.Provider
        value={{
          searchPlaceholder,
          optionsHeaderContainerRef,
          searchInputRef,
          toggleOptionsRef,
          firstOptionRef,
          filteredOptions,
          setFilteredOptions,
          options: normalizedOptions,
          isMultiple: rest.multiple,
          withSearch,
          placeholder,
          isDisabled,
          testId,
          isInvalid,
          selectedOptions,
          isShowOptions: isDropdownOpenedRef.current,
          closeOptions: closeDropdownRef.current,
          toggleOption,
          clearAll,
          selectAll,
          searchString,
          setSearchString: handlerChangeSearchString,
          t,
          virtualOverscan,
        }}
      >
        {isDisabled ? (
          <SelectInput className={inputClassName ?? className} isOpen={false} />
        ) : (
          <Dropdown
            content={
              <>
                <OptionsHeader />
                <Options />
              </>
            }
            matchWidth
            onClose={() => {
              handlerChangeSearchString('');
              setReturnFocusOnClose(true);
            }}
            initialFocusRef={withSearch ? searchInputRef : firstOptionRef}
            useVerticalPadding={false}
            returnFocusOnClose={returnFocusOnClose}
            {...dropdownProps}
          >
            {({ isOpen, onClose }) => {
              isDropdownOpenedRef.current = isOpen;
              closeDropdownRef.current = onClose;

              return (
                <SelectInput
                  className={inputClassName ?? className}
                  isOpen={isOpen}
                  ref={ref}
                />
              );
            }}
          </Dropdown>
        )}
      </Context.Provider>
    );
  },
);

export default CustomSelect;
