//  @ts-nocheck

import React, { FC } from 'react';

import { ArrayParam, StringParam, useQueryParam } from 'use-query-params';

import { AndOrQueryTInput } from '#Models/common';
import { Flex, ToggleGroup, Typeahead } from 'combinezone/core';

import FilterBase from './Filter_Base';
import { FilterName } from '../models';

const FilterAndOrQuery: FC<{
  name: typeof FilterName[keyof typeof FilterName];
  label: string;
}> = ({ name, label }) => {
  const [value = [], setValue] = useQueryParam(`${name}_values`, ArrayParam, {
    removeDefaultsFromUrl: true,
    updateType: 'replaceIn',
  });
  const [op = 'or', setOp] = useQueryParam(
    `${name}_operand`,
    StringParam,
    {
      removeDefaultsFromUrl: true,
      updateType: 'replaceIn',
    },
  );
  
  const negationOperator = op[0] === 'n' ? 'n': '';
  const operand = negationOperator? op?.substring(1) : op;
  
  return (
    <FilterBase label={label}>
      <Flex gap="24px">
        <ToggleGroup
          items={[
            {
              testId: `${name}_equal`,
              content: 'Равно',
              isActive: !negationOperator,
              onClick: () => setOp(operand),
            },
            {
              testId: `${name}_notEqual`,
              content: 'Не равно',
              isActive: negationOperator,
              onClick: () => setOp('n' + operand),
            },
          ]}
        />
        <ToggleGroup
          items={[
            {
              testId: `${name}_operand_${AndOrQueryTInput.Or}`,
              content: 'Или',
              isActive: operand === AndOrQueryTInput.Or,
              onClick: () => setOp(negationOperator + AndOrQueryTInput.Or),
            },
            {
              testId: `${name}_operand_${AndOrQueryTInput.And}`,
              content: 'И',
              isActive: operand === AndOrQueryTInput.And,
              onClick: () => setOp(negationOperator + AndOrQueryTInput.And),
            },
          ]}
        />
      </Flex>
      <Typeahead
        testId={`Typeahead__${name}`}
        suggestions={[]}
        canAddCustomParameter
        value={value}
        onChange={setValue}
      />
    </FilterBase>
  );
};

export default FilterAndOrQuery;
FilterAndOrQuery.displayName = 'FilterAndOrQuery';
