/**
 * @generated SignedSource<<cc9743ba68a109af83a63502108fb06c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EntitySource = "Bizone" | "BizoneAndClient" | "Client";
import { FragmentRefs } from "relay-runtime";
export type FraudDashboardPhishingFragment$data = {
  readonly phishing: ReadonlyArray<{
    readonly counter: number;
    readonly source: EntitySource | null;
  }> | null;
  readonly " $fragmentType": "FraudDashboardPhishingFragment";
};
export type FraudDashboardPhishingFragment$key = {
  readonly " $data"?: FraudDashboardPhishingFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"FraudDashboardPhishingFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "created"
    },
    {
      "kind": "RootArgument",
      "name": "organization"
    },
    {
      "kind": "RootArgument",
      "name": "updated"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "FraudDashboardPhishingFragment",
  "selections": [
    {
      "alias": "phishing",
      "args": [
        {
          "kind": "Variable",
          "name": "created",
          "variableName": "created"
        },
        {
          "kind": "Literal",
          "name": "hidden",
          "value": false
        },
        {
          "kind": "Variable",
          "name": "organization",
          "variableName": "organization"
        },
        {
          "kind": "Literal",
          "name": "states",
          "value": [
            "InProgress",
            "BlockPending",
            "Blocked"
          ]
        },
        {
          "kind": "Literal",
          "name": "subService",
          "value": [
            "Phishing"
          ]
        },
        {
          "kind": "Variable",
          "name": "updated",
          "variableName": "updated"
        }
      ],
      "concreteType": "FraudulentCountResponse",
      "kind": "LinkedField",
      "name": "fraudulentResource",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "counter",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "source",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ByServiceCountResponse",
  "abstractKey": null
};

(node as any).hash = "d6179d3b95668060e37a3361ff5e0080";

export default node;
