/**
 * @generated SignedSource<<e68613bae725d33efd6c8222e0337dca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LeaksDashboardSharingPlatformFragment$data = {
  readonly sharingPlatform: ReadonlyArray<{
    readonly counter: number;
  }> | null;
  readonly " $fragmentType": "LeaksDashboardSharingPlatformFragment";
};
export type LeaksDashboardSharingPlatformFragment$key = {
  readonly " $data"?: LeaksDashboardSharingPlatformFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"LeaksDashboardSharingPlatformFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "created"
    },
    {
      "kind": "RootArgument",
      "name": "organization"
    },
    {
      "kind": "RootArgument",
      "name": "updated"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "LeaksDashboardSharingPlatformFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "created",
          "variableName": "created"
        },
        {
          "kind": "Literal",
          "name": "hidden",
          "value": false
        },
        {
          "kind": "Variable",
          "name": "organization",
          "variableName": "organization"
        },
        {
          "kind": "Variable",
          "name": "updated",
          "variableName": "updated"
        }
      ],
      "concreteType": "GeneralCountResponse",
      "kind": "LinkedField",
      "name": "sharingPlatform",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "counter",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ByServiceCountResponse",
  "abstractKey": null
};

(node as any).hash = "1edbab53f660fa157ba32abe008a5a7d";

export default node;
