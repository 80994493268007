import React, { FC, Suspense, useEffect } from 'react';

import Cookies from 'js-cookie';
import { DateTime } from 'luxon';
import { Outlet } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { OverlaySpinner } from 'combinezone/core';
import { graphql } from 'babel-plugin-relay/macro';
import { useLazyLoadQuery } from 'react-relay/hooks';
import { encrypt, getEncryptedKey } from '#Modules/Auth/Pages/crypto';
import { AccountPreloaderQuery } from '#__artifacts/AccountPreloaderQuery.graphql';

import { useAccount } from './context';

export const accountQuery = graphql`
  query AccountPreloaderQuery {
    account @required(action: NONE) {
      role @required(action: NONE)
      firstName
      middleName
      lastName
      organization {
        name
        id
      }
      email
      phone
      login @required(action: NONE)
    }
    organizations {
      id
      name
      isActive
    }
  }
`;

const AccountPreloader: FC = () => {
  const data = useLazyLoadQuery<AccountPreloaderQuery>(accountQuery, {});

  const { setAccount, isAuthenticated, account } = useAccount();

  useEffect(() => setAccount(data), []);

  useEffect(() => {
    if (!account?.login) return;

    const encryptLogin = encrypt(account?.login);
    const encryptedKey = getEncryptedKey(account?.login);

    localStorage.setItem('lastUpdateTime', DateTime.now().toString());

    if (encryptLogin && !encryptedKey) {
      Cookies.set(
        encryptLogin,
        Math.floor(DateTime.local().toSeconds()).toString(),
      );
    }

    const handleBeforeUnload = () => {
      if (encryptedKey) {
        Cookies.set(
          encryptedKey,
          Math.floor(DateTime.local().toSeconds()).toString(),
        );
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [account?.login]);

  if (!isAuthenticated) {
    return null;
  }

  return (
    <Suspense fallback={<OverlaySpinner />}>
      <Outlet />
    </Suspense>
  );
};

export default observer(AccountPreloader);
AccountPreloader.displayName = 'AccountPreloader';
