import React from 'react';

import { Heading } from 'combinezone/core';

import { WorkspaceTitleOut } from './WorkspaceTitle.styles';
import { useServicesMatch } from '../../../../Hooks/useServicesMatch';

const WorkspaceTitle = () => {
  const { isFraud, isLeaks, isMedia } = useServicesMatch();

  const getTitle = () => {
    switch (true) {
      case !!isFraud:
        return 'Мошенничество';
      case !!isLeaks:
        return 'Утечки данных';
      case !!isMedia:
        return 'Инфополе';
    }
  };

  return (
    <WorkspaceTitleOut>
      <Heading>{getTitle()}</Heading>
    </WorkspaceTitleOut>
  );
};

export default WorkspaceTitle;
