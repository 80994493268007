import React, { FC } from 'react';
import { FiltersIn, FiltersOut, FiltersReset } from './Filters.styles';
import { Button } from 'combinezone/core';
import { FilterOff } from 'combinezone/icons';
import { useQueryParams } from 'use-query-params';

import { FilterName } from './models';

import {
  FilterAndOrQuery,
  FilterHidden,
  FilterPriority,
  FilterSources,
  FilterStates,
  FilterTool,
  FilterType,
  FilterStatesExtraAlert,
} from '#Components/Filters/FiltersByType';
import FiltersByEntities from '#Components/Filters/FiltersByEntities';

const Filters: FC = () => {
  const [, setParams] = useQueryParams();
  return (
    <FiltersOut>
      <FiltersIn onClick={(e) => e.stopPropagation()}>
        <FilterStatesExtraAlert />
        <FilterType />
        <FilterStates />
        <FilterAndOrQuery name={FilterName.Tags} label={'Теги'} />

        {/*
            <FilterPeriod />
          */}
        <FilterHidden />
        <FilterSources />
        <FilterTool />
        <FilterPriority />
        <FiltersByEntities />
      </FiltersIn>
      <FiltersReset>
        <Button
          testId="FiltersReset"
          LeftIcon={FilterOff}
          onClick={() => setParams({}, 'replace')}
        >
          Сбросить фильтры
        </Button>
      </FiltersReset>
    </FiltersOut>
  );
};

export default Filters;
Filters.displayName = 'FiltersAsidePanel';
