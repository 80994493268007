import { action, computed, makeObservable, observable, reaction } from 'mobx';
import storage from '../../Utils/Storage';

export class AccountStore {
  isAuthenticated: boolean = false;
  account: any | null = null;
  selectedOrganization: string | null = storage.get('organization', undefined);
  organizations: any[] = [];

  constructor() {
    makeObservable(this, {
      isAuthenticated: observable,
      selectedOrganization: observable,
      setAccount: action,
      setOrganization: action,
      isAdmin: computed,
      organizationsList: computed,
    });

    reaction(
      () => this.selectedOrganization,
      () => {
        storage.set('organization', this.selectedOrganization);
      },
    );
  }

  setAccount = (
    accountData: {
      account: any;
      organizations: any;
    } | null,
  ) => {
    if (!accountData) {
      return;
    }
    this.account = accountData.account;
    this.organizations = accountData.organizations;
    if (
      this.selectedOrganization === undefined ||
      this.account.role !== 'Admin'
    ) {
      this.selectedOrganization = accountData.account.organization.name;
    }
    this.isAuthenticated = true;
  };

  setOrganization = (selectedOrganization: string | null) => {
    this.selectedOrganization = selectedOrganization;
  };

  resetAccount = action(() => {
    this.account = null;
    this.isAuthenticated = false;
  });


  get isAdmin() {
    return this?.account?.role === 'Admin';
  }

  get organizationsList() {
    return this.organizations.map((organization: any) => organization.name);
  }
}

export default new AccountStore();
