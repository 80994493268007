import React, { FC, useCallback } from 'react';
import { IconButton } from 'combinezone/core';
import { FileExport } from 'combinezone/icons';
import { useQueryParams } from 'use-query-params';
import { useModals } from '#Components/ModalPortal';
import { observer } from 'mobx-react-lite';
import ExportEntitiesModal from './ExportEntities_Modal';
import { useExportTaskQueue } from '#Components/PendingQueues';

const ExportEntitiesButton: FC = () => {
  const [params] = useQueryParams();
  const { openModal, closeModal } = useModals();
  const { isPending, resetStatus } = useExportTaskQueue();

  const handleClick = useCallback(() => {
    if (isPending) {
      return;
    }
    openModal(
      <ExportEntitiesModal
        filters={params}
        onClose={() => {
          closeModal();
          resetStatus();
        }}
      />,
    );
  }, [isPending, params]);

  return (
    <IconButton
      isLoading={isPending}
      icon={FileExport}
      tooltip="Экспорт"
      testId="ExportResults"
      onClick={handleClick}
    />
  );
};

export default observer(ExportEntitiesButton);
ExportEntitiesButton.displayName = 'ExportEntitiesButton';
