import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useKey } from 'rooks';

import { Close } from 'combinezone/icons';

import { KeyboardKeys, useIsClosable } from 'combinezone/utils';
import {
  Dropdown,
  DropdownProps,
  InputActionIcon,
  Spinner,
  Text,
} from 'combinezone/core';

import { TypeaheadContext } from './TypeaheadContext';
import { Suggestions } from './components/Suggestions';
import { TypeaheadInput } from './components/TypeaheadInput';
import { TypeaheadContainer, SpinnerContainer } from './TypeaheadSingle.styles';

export type TypeaheadProps = {
  getSuggestions: (value: string) => Promise<string[]>;
  dropdownProps?: Omit<DropdownProps, 'children'>;
  inputValue?: string;
  isDisabled?: boolean;
  isInvalid?: boolean;
  onChange: (suggestion: string | undefined) => void;
  onInputChange?: () => void;
  placeholder?: string;
  testId: string;
  value: string | undefined;
};

const TypeaheadSingle = forwardRef<HTMLInputElement, TypeaheadProps>(
  (
    {
      dropdownProps,
      getSuggestions,
      inputValue: defaultInputValue = '',
      isDisabled,
      isInvalid,
      onChange,
      onInputChange,
      placeholder,
      testId,
      value,
    },
    forwardedRef,
  ) => {
    const [inputValue, setInputValue] = useState(defaultInputValue);

    const [filteredSuggestions, setFilteredSuggestions] = useState<string[]>(
      [],
    );

    const [isLoadingSuggestions, setIsLoadingSuggestions] = useState(false);

    useEffect(() => {
      (async () => {
        if (inputValue.trim().length < 3) {
          setFilteredSuggestions([]);
          return;
        }
        setIsLoadingSuggestions(true);
        const receivedSuggestions = await getSuggestions(inputValue);
        setIsLoadingSuggestions(false);
        setFilteredSuggestions(receivedSuggestions);
      })();
    }, [inputValue, getSuggestions]);

    const { close, isOpen, open } = useIsClosable();

    const inputRef = useRef<HTMLInputElement>(null);

    const suggestionsContainerRef = useRef<HTMLDivElement | null>(null);

    useKey([KeyboardKeys.Escape], close);

    const showNotFoundPlaceholder = useMemo(
      () => !!inputValue && !filteredSuggestions.length,
      [filteredSuggestions, inputValue],
    );

    const focusOnInput = useCallback((): void => {
      inputRef.current?.focus();
    }, [inputRef]);

    const handleChangeInput = useCallback(
      (newValue: string): void => {
        onInputChange?.();
        setInputValue(newValue);
        if (!isOpen) {
          open();
        }
      },
      [onInputChange, isOpen, open],
    );

    const handleSelectSuggestion = useCallback(
      (suggestion: string): void => {
        onChange(suggestion);
        setInputValue('');
      },
      [onChange],
    );

    return (
      <TypeaheadContext.Provider
        value={{
          close,
          filteredSuggestions,
          handleSelectSuggestion,
          inputValue,
          isDisabled,
          isInvalid,
          value,
          setInputValue,
          showNotFoundPlaceholder,
          suggestionsContainerRef,
          testId,
        }}
      >
        <Dropdown
          usePortal={false}
          content={
            isLoadingSuggestions ? (
              <SpinnerContainer>
                <Spinner size="sm" />
              </SpinnerContainer>
            ) : (
              <Suggestions ref={suggestionsContainerRef} />
            )
          }
          initialFocusRef={inputRef}
          isOpen={
            isOpen &&
            inputValue.trim().length > 2 &&
            (!!filteredSuggestions.length || showNotFoundPlaceholder)
          }
          matchWidth
          position="bottom"
          {...dropdownProps}
        >
          <TypeaheadContainer
            isDisabled={isDisabled}
            isInvalid={isInvalid}
            onClick={focusOnInput}
            ref={forwardedRef}
            onBlur={close}
          >
            {!!value ? (
              <Text>{value}</Text>
            ) : (
              <TypeaheadInput
                changeDelayMs={300}
                data-test-id={testId}
                isDisabled={isDisabled}
                isInvalid={isInvalid}
                onBlur={close}
                onChange={handleChangeInput}
                onClick={() => {
                  if (filteredSuggestions.length) {
                    open();
                  }
                }}
                onFocus={open}
                placeholder={!value ? placeholder : undefined}
                ref={inputRef}
                value={inputValue}
              />
            )}

            {!isDisabled && (!!value || !!inputValue) && (
              <InputActionIcon
                Icon={Close}
                key="clear"
                onClick={() => {
                  setInputValue('');
                  onChange(undefined);
                  setTimeout(focusOnInput, 0);
                }}
                testId={`${testId}-clear`}
              />
            )}
          </TypeaheadContainer>
        </Dropdown>
      </TypeaheadContext.Provider>
    );
  },
);
export default TypeaheadSingle;
TypeaheadSingle.displayName = 'TypeaheadSingle';
