/**
 * @generated SignedSource<<99f8c686db9d77c5b618c3aa8757c5cb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DateResponse = "Long" | "String";
export type Priority = "High" | "Low" | "Medium";
export type State = "BlockPending" | "Blocked" | "Checked" | "Correcting" | "CorrectionDenied" | "DelegationRestored" | "Edited" | "InCommunication" | "InProgress" | "Irrelevant" | "Legitimate" | "Monitoring" | "New" | "NoContent" | "NoViolations" | "Settled" | "Unknown" | "Violation" | "WaitingForCustomer" | "WaitingForSupport";
import { FragmentRefs } from "relay-runtime";
export type commonsServiceEntityFragment$data = {
  readonly created: DateResponse;
  readonly dataLeaks?: ReadonlyArray<{
    readonly leaked: DateResponse;
  }> | null;
  readonly description: string | null;
  readonly id: string;
  readonly priority: Priority | null;
  readonly state: State;
  readonly tags: ReadonlyArray<string> | null;
  readonly updated: DateResponse;
  readonly value: string | null;
  readonly " $fragmentType": "commonsServiceEntityFragment";
};
export type commonsServiceEntityFragment$key = {
  readonly " $data"?: commonsServiceEntityFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"commonsServiceEntityFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "format",
    "value": "Timestamp"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "commonsServiceEntityFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "created",
      "storageKey": "created(format:\"Timestamp\")"
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "updated",
      "storageKey": "updated(format:\"Timestamp\")"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tags",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "state",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "priority",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "DataLeak",
          "kind": "LinkedField",
          "name": "dataLeaks",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "leaked",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "Database",
      "abstractKey": null
    }
  ],
  "type": "ServiceEntity",
  "abstractKey": "__isServiceEntity"
};
})();

(node as any).hash = "8fc4404c1e390559793829066f61b53b";

export default node;
