/**
 * @generated SignedSource<<bd29390745d8d67ae247716b50748d49>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EntitySource = "Bizone" | "BizoneAndClient" | "Client";
import { FragmentRefs } from "relay-runtime";
export type FraudDashboardMobileApplicationFragment$data = {
  readonly mobileApplication: ReadonlyArray<{
    readonly counter: number;
    readonly source: EntitySource | null;
  }> | null;
  readonly " $fragmentType": "FraudDashboardMobileApplicationFragment";
};
export type FraudDashboardMobileApplicationFragment$key = {
  readonly " $data"?: FraudDashboardMobileApplicationFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"FraudDashboardMobileApplicationFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "created"
    },
    {
      "kind": "RootArgument",
      "name": "organization"
    },
    {
      "kind": "RootArgument",
      "name": "updated"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "FraudDashboardMobileApplicationFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "created",
          "variableName": "created"
        },
        {
          "kind": "Literal",
          "name": "hidden",
          "value": false
        },
        {
          "kind": "Variable",
          "name": "organization",
          "variableName": "organization"
        },
        {
          "kind": "Literal",
          "name": "states",
          "value": [
            "Correcting",
            "CorrectionDenied",
            "Edited",
            "Legitimate",
            "WaitingForCustomer",
            "WaitingForSupport"
          ]
        },
        {
          "kind": "Variable",
          "name": "updated",
          "variableName": "updated"
        }
      ],
      "concreteType": "GeneralCountResponse",
      "kind": "LinkedField",
      "name": "mobileApplication",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "counter",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "source",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ByServiceCountResponse",
  "abstractKey": null
};

(node as any).hash = "918ff39d46936ce1d487fda329e2a9f6";

export default node;
