import styled from 'styled-components';

export const DropdownValuesOut = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid
    ${({ theme }) => theme.components.input.colors.border.default};
  border-radius: 4px;
  background: ${({ theme }) =>
    theme.components.input.colors.background.default};
  height: 40px;
  & button {
    width: 24px;
    height: 38px;
    border-radius: 0 4px 4px 0;
    padding: 7px 4px;
    border-left: 1px solid
      ${({ theme }) => theme.components.input.colors.border.default};
    & svg {
      width: 16px;
    }
  }
`;

export const CurrentValue = styled.div`
  display: flex;
  padding: 0 16px;
  align-items: center;
  justify-content: center;
  width: 104px;
`;
