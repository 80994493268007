import React, { Suspense, useRef } from 'react';
import { Flex, Text, Spinner } from 'combinezone/core';
import { graphql } from 'babel-plugin-relay/macro';
import { useLazyLoadQuery } from 'react-relay/hooks';
import MainPage from '../Pages/Main';
import { NewsLayoutQuery } from '#__artifacts/NewsLayoutQuery.graphql';
import { matchPath } from 'react-router';

const newsQuery = graphql`
  query NewsLayoutQuery($id: String!, $includeSelected: Boolean!) {
    ...MainPageNewsFeedFragment
    selected: node(id: $id) @include(if: $includeSelected) {
      ...NewsFeedItemFragment
    }
  }
`;

const NewsLayout = () => {
  const { current: selectedNewsId } = useRef<string>(
    ((): string => {
      const matches = matchPath(`/news/:newsId`, window.location.pathname);
      return matches?.params?.newsId ?? '';
    })(),
  );

  const queryRef = useLazyLoadQuery<NewsLayoutQuery>(newsQuery, {
    id: selectedNewsId,
    includeSelected: Boolean(selectedNewsId),
  });

  return (
    <Suspense
      fallback={
        <Flex
          direction="column"
          gap="24px"
          alignItems="center"
          style={{ paddingTop: '240px' }}
        >
          <Spinner />
          <Text>Подождите, пожалуйста, данные обрабатываются...</Text>
        </Flex>
      }
    >
      <MainPage queryRef={queryRef} />
    </Suspense>
  );
};

export default NewsLayout;
NewsLayout.displayName = 'NewsLayout';
